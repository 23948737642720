import React, { useState, useEffect } from "react";
import {
  adminGetFundingRequests,
  adminUpdateTransfer,
  adminProcessTransfer,
} from "../../../redux/actions/adminActions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import { getRequestStatusSelectListOptions } from "../../../utils/utils";
import { Link } from "react-router-dom";
import { filterBaseCurrency } from "../../../config/constants";
import { NotificationManager } from "react-notifications";

const TransferRequests = props => {
  const [state, setState] = useState({
    editTransferErrors: "",
    showTransferModal: false,
    disableEditTransferBtn: false,
    selectedTransfer: {},
    loading: true,
  });
  const [clients, setClients] = useState({});
  const [transfers, setTransfer] = useState(null);

  useEffect(() => {
    triggerAdminGetFundingRequests();
  }, [props.match.params.status]);

  const triggerAdminGetFundingRequests = () => {
    setTransfer([]);
    adminGetFundingRequests("all", "transfer", props.match.params.status)
      .then(res => {
        setClients(res.data.clients);
        setTransfer(res.data.transfers);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.error(err));
  };

  const handleUpdateTransfer = e => {
    let name = e.target.name;
    let value = e.target.value;

    const selectedTransfer = state.selectedTransfer;
    selectedTransfer[name] = value;
    setState(prevState => {
      return { ...prevState, selectedTransfer };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        disableEditTransferBtn: false,
        showTransferModal: false,
      };
    });
  };

  const handleMarkProcessed = transferId => {
    if (window.confirm("Do you want to mark this transfer as processed?")) {
      adminProcessTransfer(transferId)
        .then(res => {
          if (res.data.success) {
            createNotification("success");
            handleCloseModal();
            triggerAdminGetFundingRequests();
          } else createNotification("error");
        })
        .catch(err => {
          createNotification("error");
          console.error(err);
        });
    }
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const selectedTransfer = state.selectedTransfer;

    if (
      selectedTransfer.status === "rejected" &&
      (!selectedTransfer.comment || selectedTransfer.comment.trim() === "")
    ) {
      alert("You cannot reject transfer without a reason");
      return false;
    }

    setState(prevState => {
      return { ...prevState, disableEditTransferBtn: true };
    });

    if (form.checkValidity()) {
      let updateTransfer = {
        _id: selectedTransfer._id,
        status: selectedTransfer.status,
        comment: selectedTransfer.comment,
      };

      adminUpdateTransfer(updateTransfer)
        .then(res => {
          if (res.data.success) {
            createNotification("success");
            handleCloseModal();
            triggerAdminGetFundingRequests();
          } else createNotification("error");
        })
        .catch(err => {
          createNotification("error");
          console.error(err);
        });
    }
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the transfer request.",
          "Transfer Update",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  const customFilterCurrency = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.transferCurrency === filterVal);
    }
    return data;
  };

  const columns = [
    {
      dataField: "srcAccount",
      text: "SRC. ACCOUNT",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "destAccount",
      text: "DEST. ACCOUNT",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "firstName",
      text: "FIRST NAME",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "lastName",
      text: "LAST NAME",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "transferAmount",
      text: "AMOUNT",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "transferCurrency",
      text: "CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    {
      dataField: "sourceMTStatus",
      text: "WITHDRAWN",
    },
    {
      dataField: "destinationMTStatus",
      text: "DEPOSITED",
    },
    {
      dataField: "timestamp",
      text: "REQUESTED",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setState(prevState => {
        return { ...prevState, showTransferModal: true, selectedTransfer: row };
      });
    },
  };

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (
      (row.sourceMTStatus && row.sourceMTStatus !== "SUCCESS") ||
      (row.destinationMTStatus && row.destinationMTStatus !== "SUCCESS")
    ) {
      style = { backgroundColor: "#ff0000", color: "#fff" };
    }

    return style;
  };

  let data = [];
  if (!!transfers) {
    transfers.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(transfers[keyIndex].timestamp)) {
        transfers[keyIndex].timestamp = moment(
          transfers[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      transfers[
        keyIndex
      ].srcAccount = `${transfers[keyIndex].sourceAccount} (${transfers[keyIndex].sourceAccountPlatform})`;
      transfers[
        keyIndex
      ].destAccount = `${transfers[keyIndex].destinationAccount} (${transfers[keyIndex].destinationAccountPlatform})`;

      if (clients[transfers[keyIndex].userId]) {
        transfers[keyIndex].firstName =
          clients[transfers[keyIndex].userId].name;
        transfers[keyIndex].lastName =
          clients[transfers[keyIndex].userId].surname;
      }

      transfers[keyIndex].transferAmount = parseFloat(
        transfers[keyIndex].transferAmount
      ).toFixed(2);

      transfers[keyIndex].action = (
        <Button
          type="button"
          variant="success"
          onClick={() => handleMarkProcessed(transfers[keyIndex]._id)}
        >
          Processed
        </Button>
      );

      data.push(transfers[keyIndex]);
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="px-3">
            Internal Transfers -{" "}
            <span className="text-capitalize">{props.match.params.status}</span>{" "}
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "pending" ? "disabled" : "",
              ]}
              as={Link}
              to={"/transfer-requests/pending"}
            >
              Pending
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "approved" ? "disabled" : "",
              ]}
              as={Link}
              to={"/transfer-requests/approved"}
            >
              Approved
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "rejected" ? "disabled" : "",
              ]}
              as={Link}
              to={"/transfer-requests/rejected"}
            >
              Rejected
            </Button>
            {state.loading ? (
              <Spinner animation="border" size="sm" className="float-right" />
            ) : null}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            filter={filterFactory()}
            rowEvents={rowEvents}
            rowStyle={rowStyle}
            headerClasses="tableHeaderClass"
          />

          <Modal
            show={state.showTransferModal}
            onHide={handleCloseModal}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update transfer</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={e => handleSubmit(e)}>
                <Form.Group>
                  <Form.Label>Src. account</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedTransfer.sourceAccount}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Dest. account</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedTransfer.destinationAccount}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={`${state.selectedTransfer.transferAmount} ${state.selectedTransfer.transferCurrency}`}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="comment"
                    onChange={e => handleUpdateTransfer(e)}
                    defaultValue={state.selectedTransfer.comment}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    required
                    onChange={e => handleUpdateTransfer(e)}
                    defaultValue={state.selectedTransfer.status}
                  >
                    <option value="">Choose transfer status</option>
                    {getRequestStatusSelectListOptions()}
                  </Form.Control>
                </Form.Group>

                <Form.Text style={{ color: "red" }}>
                  {state.editTransferErrors.errors ||
                    state.editTransferErrors.server}
                  <br />
                  <br />
                </Form.Text>

                <Form.Row className="justify-content-between">
                  <Button
                    variant="secondary"
                    className="greyButton"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    className="darkBlueButton"
                    style={{ fontWeight: "normal" }}
                    disabled={state.disableEditTransferBtn}
                  >
                    {state.disableEditTransferBtn ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Update
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Form.Row>
              </Form>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default TransferRequests;
