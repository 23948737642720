import React, { useState, useEffect } from "react";
import {
  getAdmins,
  adminGetFundingRequests,
  adminUpdateWithdrawal,
  adminProcessWithdrawal,
  adminUpdateWithdrawalZotaPay,
  adminProcessWithdrawalZotaPay,
  adminGetBankAccount,
} from "../../../redux/actions/adminActions";
import { filterBaseCurrency } from "../../../config/constants";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import {
  capitalize,
  getRequestStatusSelectListOptions,
} from "../../../utils/utils";
import { Link } from "react-router-dom";
import BankDetailsModal from "../../../components/Modals/BankDetails/BankDetails";
import { NotificationManager } from "react-notifications";

const WithdrawalRequests = props => {
  const [state, setState] = useState({
    showWithdrawalModal: false,
    disableEditWithdrawalBtn: false,
    showBankDetailsModal: false,
    selectedWithdrawal: {},
    loading: true,
  });
  const [admins, setAdmins] = useState({});
  const [clients, setClients] = useState({});
  const [withdrawals, setWithdrawals] = useState(null);
  const [selectedBankDetails, setSelectedBankDetails] = useState(null);

  useEffect(() => {
    getAdmins()
      .then(res => {
        setAdmins(res.data.admins);
      })
      .catch(err => {
        createNotification("error");
        console.error(err);
      });
  }, []);

  useEffect(() => {
    triggerAdminGetFundingRequests();
  }, [props.match.params.status]);

  const triggerAdminGetFundingRequests = () => {
    setWithdrawals([]);
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    adminGetFundingRequests("all", "withdrawal", props.match.params.status)
      .then(res => {
        setClients(res.data.clients);
        setWithdrawals(res.data.transfers);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => {
        createNotification("error");
        console.error(err);
      });
  };

  const handleUpdateWithdrawal = e => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "dontEmailUser" || name === "modifyDealAmount") {
      value = e.target.checked;
    }

    const selectedWithdrawal = state.selectedWithdrawal;
    selectedWithdrawal[name] = value;
    setState(prevState => {
      return { ...prevState, selectedWithdrawal };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        disableEditWithdrawalBtn: false,
        showWithdrawalModal: false,
        showBankDetailsModal: false,
      };
    });
  };

  const handleApproveWithdrawal = (withdrawalId, paymentGatewayService) => {
    if (window.confirm("Are you sure you want to approve this withdrawal?")) {
      if (paymentGatewayService === "ZP")
        adminProcessWithdrawalZotaPay(withdrawalId)
          .then(res => {
            if (res.data.success) {
              createNotification("successZotaPay");
            } else createNotification("error");

            handleCloseModal();
            triggerAdminGetFundingRequests();
          })
          .catch(err => {
            createNotification("error");
            console.error(err);
          });
      else
        adminProcessWithdrawal(withdrawalId)
          .then(res => {
            if (res.data.success) {
              createNotification("success");
            } else createNotification("error");

            handleCloseModal();
            triggerAdminGetFundingRequests();
          })
          .catch(err => {
            createNotification("error");
            console.error(err);
          });
    }
  };

  const handleRejectWithdrawal = withdrawal => {
    withdrawal.status = "rejected";
    setState(prevState => {
      return {
        ...prevState,
        showWithdrawalModal: true,
        selectedWithdrawal: withdrawal,
      };
    });
  };

  const handleAdminGetBankAccount = bankId => {
    adminGetBankAccount(bankId)
      .then(res => {
        setSelectedBankDetails(res.data);
        setState(prevState => {
          return {
            ...prevState,
            showBankDetailsModal: true,
          };
        });
      })
      .catch(err => {
        createNotification("error");
        console.error(err);
      });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    let { selectedWithdrawal } = state;

    if (
      selectedWithdrawal.status === "rejected" &&
      (!selectedWithdrawal.comment || selectedWithdrawal.comment.trim() === "")
    ) {
      createNotification(
        "error",
        "You cannot reject withdrawal without a reason"
      );
      return false;
    }

    setState(prevState => {
      return { ...prevState, disableEditWithdrawalBtn: true };
    });

    if (form.checkValidity()) {
      let updateWithdrawal = {
        _id: selectedWithdrawal._id,
        status: selectedWithdrawal.status,
        comment: selectedWithdrawal.comment,
        dontEmailUser: selectedWithdrawal.dontEmailUser,
        modifyDealAmount: selectedWithdrawal.modifyDealAmount,
        withdrawalAmount: selectedWithdrawal.withdrawalAmount,
      };

      if (selectedWithdrawal.isZotaPay)
        adminUpdateWithdrawalZotaPay(updateWithdrawal)
          .then(res => {
            if (res.data.success) {
              createNotification("successZotaPay");
            } else createNotification("error");

            handleCloseModal();
            triggerAdminGetFundingRequests();
          })
          .catch(err => {
            createNotification("error");
            console.error(err);
          });
      else
        adminUpdateWithdrawal(updateWithdrawal)
          .then(res => {
            if (res.data.success) {
              createNotification("success");
            } else createNotification("error");

            handleCloseModal();
            triggerAdminGetFundingRequests();
          })
          .catch(err => {
            createNotification("error");
            console.error(err);
          });
    }
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the withdrawal request.",
          "",
          5000
        );
        break;
      case "successZotaPay":
        NotificationManager.success(
          "You have successfully updated the withdrawal request. Waiting for ZotaPay to process the withdrawal.",
          "",
          5000
        );
        break;
      case "error":
        NotificationManager.error(message || "Something went wrong!", "", 5000);
        break;
      default:
    }
  };

  const customFilterCurrency = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.withdrawalCurrency === filterVal);
    }
    return data;
  };

  let columns = [
    {
      dataField: "gateway",
      text: "GATEWAY",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "timestamp",
      text: "TIME OF REQUEST",
      sort: true,
    },
    {
      dataField: "withdrawalType",
      text: "PAYMENT METHOD",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "firstName",
      text: "FIRST NAME",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "lastName",
      text: "LAST NAME",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "requestId",
      text: "TRANSACTION ID",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "accountNumber",
      text: "TRADING ACCOUNT NUMBER",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "withdrawalAmount",
      text: "AMOUNT",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "withdrawalCurrency",
      text: "CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    // {
    //   dataField: "fee",
    //   text: "FEE",
    // },
    // {
    //   dataField: "document",
    //   text: "DOCUMENT",
    // },
  ];
  if (props.match.params.status === "pending") {
    columns = [
      ...columns,
      {
        dataField: "transactionDetails",
        text: "TRANSACTION DETAILS",
      },
      {
        dataField: "reject",
        text: "",
      },
      {
        dataField: "approve",
        text: "",
      },
    ];
  }
  if (
    props.match.params.status !== "pending" &&
    props.match.params.status !== "incomplete"
  ) {
    columns = [
      ...columns,
      {
        dataField: "transactionDetails",
        text: "TRANSACTION DETAILS",
      },
      {
        dataField: "timeApproved",
        text: "PROCESSED TIME",
      },
      {
        dataField: "adminId",
        text: "PROCESSED BY",
      },
    ];
  }

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setState(prevState => {
        return {
          ...prevState,
          showWithdrawalModal: true,
          selectedWithdrawal: row,
        };
      });
    },
  };

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (
      row.status !== "approved" &&
      (row.statusSC === "approved" || row.statusZP === "approved")
    ) {
      style = { backgroundColor: "#adff96" };
    }

    if (
      row.status === "rejected" ||
      row.status === "declined" ||
      row.status === "error"
    ) {
      style = { backgroundColor: "#ffb5b5" };
    }

    if (row.MTStatus && row.MTStatus !== "SUCCESS") {
      style = { backgroundColor: "#ff0000", color: "#fff" };
    }

    return style;
  };

  let data = [];
  if (!!withdrawals) {
    withdrawals.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (withdrawals[keyIndex].withdrawalType.toLowerCase() === "wire") {
        withdrawals[keyIndex].gateway = "Bank Wire";

        withdrawals[keyIndex].transactionDetails = (
          <Button
            variant="link"
            className="p-0 text-dark fontWeight600"
            style={{ fontSize: "0.7rem", textDecoration: "underline" }}
            onClick={() =>
              handleAdminGetBankAccount(withdrawals[keyIndex].bankId)
            }
          >
            View
          </Button>
        );
      } else {
        withdrawals[keyIndex].gateway = `${
          withdrawals[keyIndex].paymentGatewayService
            ? withdrawals[keyIndex].paymentGatewayService + " - "
            : ""
        }${capitalize(withdrawals[keyIndex].withdrawalType.replace("_", " "))}`;
        // withdrawals[
        //   keyIndex
        // ].gateway = `${withdrawals[keyIndex].withdrawalType}`;
      }

      withdrawals[keyIndex].paymentMethod =
        withdrawals[keyIndex].withdrawalPaymentName ||
        capitalize(withdrawals[keyIndex].withdrawalType.replace("_", " "));

      if (Number.isInteger(withdrawals[keyIndex].timestamp)) {
        withdrawals[keyIndex].timestamp = moment(
          withdrawals[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (clients[withdrawals[keyIndex].userId]) {
        withdrawals[keyIndex].firstName =
          clients[withdrawals[keyIndex].userId].name;
        withdrawals[keyIndex].lastName =
          clients[withdrawals[keyIndex].userId].surname;
      }

      withdrawals[keyIndex].withdrawalAmount = parseFloat(
        withdrawals[keyIndex].withdrawalAmount
      ).toFixed(2);

      withdrawals[keyIndex].fee = "N/A";

      if (
        withdrawals[keyIndex].adminId &&
        withdrawals[keyIndex].adminId.length === 24
      ) {
        withdrawals[keyIndex].adminId = admins[withdrawals[keyIndex].adminId];
      }

      withdrawals[keyIndex].document = (
        <a
          href="#"
          className="text-dark"
          style={{ textDecoration: "underline" }}
        >
          View
        </a>
      );

      if (props.match.params.status === "pending") {
        withdrawals[keyIndex].reject = (
          <Button
            type="button"
            variant="link"
            className="redButton text-white"
            onClick={() => handleRejectWithdrawal(withdrawals[keyIndex])}
          >
            REJECT
          </Button>
        );
        withdrawals[keyIndex].approve = (
          <Button
            type="button"
            variant="link"
            className="blueButton text-white"
            onClick={() =>
              handleApproveWithdrawal(
                withdrawals[keyIndex]._id,
                withdrawals[keyIndex].paymentGatewayService
              )
            }
          >
            APPROVE
          </Button>
        );
      } else {
        if (Number.isInteger(withdrawals[keyIndex].timeApproved)) {
          withdrawals[keyIndex].timeApproved = moment(
            withdrawals[keyIndex].timeApproved * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
      }

      data.push(withdrawals[keyIndex]);
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="px-3">
              Withdrawal requests -{" "}
              <span className="text-capitalize">
                {props.match.params.status}
              </span>{" "}
              {state.loading ? <Spinner animation="border" /> : null}
              <Button
                type="button"
                variant="secondary"
                className={[
                  "grayButton ml-3",
                  props.match.params.status === "historical" ? "disabled" : "",
                ]}
                as={Link}
                to={"/withdrawal-requests/historical"}
              >
                Historical
              </Button>
              <Button
                type="button"
                variant="secondary"
                className={[
                  "grayButton ml-3",
                  props.match.params.status === "pending" ? "disabled" : "",
                ]}
                as={Link}
                to={"/withdrawal-requests/pending"}
              >
                Pending
              </Button>
              <Button
                type="button"
                variant="secondary"
                className={[
                  "grayButton ml-3",
                  props.match.params.status === "incomplete" ? "disabled" : "",
                ]}
                as={Link}
                to={"/withdrawal-requests/incomplete"}
              >
                Incomplete
              </Button>
              <Button
                type="button"
                variant="secondary"
                className={[
                  "grayButton ml-3",
                  props.match.params.status === "error" ? "disabled" : "",
                ]}
                as={Link}
                to={"/withdrawal-requests/error"}
              >
                Errors
              </Button>
              {state.loading ? (
                <Spinner animation="border" size="sm" className="float-right" />
              ) : null}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              bordered={false}
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              pagination={pagination}
              filter={filterFactory()}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              headerClasses="tableHeaderClass"
            />

            <Modal
              show={state.showWithdrawalModal}
              onHide={handleCloseModal}
              size="md"
            >
              <Modal.Header closeButton>
                <Modal.Title>Update withdrawal</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form onSubmit={e => handleSubmit(e)}>
                  <Form.Group>
                    <Form.Label>Account</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={state.selectedWithdrawal.accountNumber}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Currency</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={state.selectedWithdrawal.withdrawalCurrency}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="w-100">
                      Deal amount
                      <Form.Group
                        controlId="modifyDealAmount"
                        className="d-inline-block float-right m-0"
                      >
                        <Form.Check
                          type="checkbox"
                          name="modifyDealAmount"
                          label="Modify deal amount"
                          checked={state.selectedWithdrawal.modifyDealAmount}
                          onChange={e => handleUpdateWithdrawal(e)}
                        />
                      </Form.Group>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      step="any"
                      name="withdrawalAmount"
                      disabled={!state.selectedWithdrawal.modifyDealAmount}
                      defaultValue={state.selectedWithdrawal.withdrawalAmount}
                      onChange={e => handleUpdateWithdrawal(e)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      defaultValue={state.selectedWithdrawal.withdrawalType}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="2"
                      name="comment"
                      onChange={e => handleUpdateWithdrawal(e)}
                      defaultValue={state.selectedWithdrawal.comment}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      name="status"
                      required
                      onChange={e => handleUpdateWithdrawal(e)}
                      defaultValue={state.selectedWithdrawal.status}
                    >
                      <option value="">Choose withdrawal status</option>
                      {getRequestStatusSelectListOptions()}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="dontEmailUser">
                    <Form.Check
                      type="checkbox"
                      name="dontEmailUser"
                      label="Do not send email to user"
                      checked={state.selectedWithdrawal.dontEmailUser}
                      onChange={e => handleUpdateWithdrawal(e)}
                    />
                  </Form.Group>

                  <Form.Row className="justify-content-between">
                    <Button
                      variant="secondary"
                      className="greyButton"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      className="darkBlueButton"
                      style={{ fontWeight: "normal" }}
                      disabled={state.disableEditWithdrawalBtn}
                    >
                      {state.disableEditWithdrawalBtn ? (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Update
                        </>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Form.Row>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>

      {state.showBankDetailsModal ? (
        <BankDetailsModal
          handleModalClose={handleCloseModal}
          bankDetails={selectedBankDetails}
        />
      ) : null}
    </>
  );
};

export default WithdrawalRequests;
