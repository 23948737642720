import React, { useState, useEffect } from "react";
import { getTransferHistory } from "../../../redux/actions/adminActions";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, Row, Col, Spinner, Tabs, Tab } from "react-bootstrap";
import moment from "moment";

const HistoricalRequests = () => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(null);

  useEffect(() => {
    getTransferHistory()
      .then(res => {
        setHistory(res.data);
        setLoading(false);
      })
      .catch(err => console.error(err));
  }, []);

  const columnsDeposits = [
    {
      dataField: "accountNumber",
      text: "Acc. number",
      sort: true,
    },
    {
      dataField: "depositType",
      text: "Type",
      sort: true,
    },
    {
      dataField: "depositAmountSent",
      text: "Deal amount",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "comment",
      text: "Comment",
    },
    {
      dataField: "timestamp",
      text: "Requested",
      sort: true,
    },
    {
      dataField: "timeUpdated",
      text: "Updated",
      sort: true,
    },
  ];

  const columnsWithdrawals = [
    {
      dataField: "accountNumber",
      text: "Acc. number",
      sort: true,
    },
    {
      dataField: "withdrawalAmount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "comment",
      text: "Comment",
    },
    {
      dataField: "timestamp",
      text: "Requested",
      sort: true,
    },
    {
      dataField: "timeUpdated",
      text: "Updated",
      sort: true,
    },
  ];

  const columnsTransfers = [
    {
      dataField: "sourceAccount",
      text: "Src. account",
      sort: true,
    },
    {
      dataField: "destinationAccount",
      text: "Dest. account",
      sort: true,
    },
    {
      dataField: "transferAmount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "comment",
      text: "Comment",
    },
    {
      dataField: "timestamp",
      text: "Requested",
      sort: true,
    },
    {
      dataField: "timeUpdated",
      text: "Updated",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  let dataDeposits = [];
  if (!!history && history.deposits) {
    Object.keys(history.deposits).forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(history.deposits[keyIndex].timestamp)) {
        history.deposits[keyIndex].timestamp = moment(
          history.deposits[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();

        if (
          history.deposits[keyIndex].status === "approved" &&
          history.deposits[keyIndex].timeApproved
        ) {
          history.deposits[keyIndex].timeUpdated = moment(
            history.deposits[keyIndex].timeApproved * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
        if (
          history.deposits[keyIndex].status === "rejected" &&
          history.deposits[keyIndex].timeRejected
        ) {
          history.deposits[keyIndex].timeUpdated = moment(
            history.deposits[keyIndex].timeRejected * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
      }

      history.deposits[
        keyIndex
      ].depositAmountSent = `${history.deposits[keyIndex].depositCurrency} ${history.deposits[keyIndex].depositAmountSent}`;

      dataDeposits.push(history.deposits[keyIndex]);
    });
  }

  let dataWithdrawals = [];
  if (!!history && history.withdrawals) {
    Object.keys(history.withdrawals).forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(history.withdrawals[keyIndex].timestamp)) {
        history.withdrawals[keyIndex].timestamp = moment(
          history.withdrawals[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();

        if (
          history.withdrawals[keyIndex].status === "approved" &&
          history.withdrawals[keyIndex].timeApproved
        ) {
          history.withdrawals[keyIndex].timeUpdated = moment(
            history.withdrawals[keyIndex].timeApproved * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
        if (
          history.withdrawals[keyIndex].status === "rejected" &&
          history.withdrawals[keyIndex].timeRejected
        ) {
          history.withdrawals[keyIndex].timeUpdated = moment(
            history.withdrawals[keyIndex].timeRejected * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
      }

      history.withdrawals[
        keyIndex
      ].withdrawalAmount = `${history.withdrawals[keyIndex].withdrawalCurrency} ${history.withdrawals[keyIndex].withdrawalAmount}`;

      dataWithdrawals.push(history.withdrawals[keyIndex]);
    });
  }

  let dataTransfers = [];
  if (!!history && history.transfers) {
    Object.keys(history.transfers).forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(history.transfers[keyIndex].timestamp)) {
        history.transfers[keyIndex].timestamp = moment(
          history.transfers[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();

        if (
          history.transfers[keyIndex].status === "approved" &&
          history.transfers[keyIndex].timeApproved
        ) {
          history.transfers[keyIndex].timeUpdated = moment(
            history.transfers[keyIndex].timeApproved * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
        if (
          history.transfers[keyIndex].status === "rejected" &&
          history.transfers[keyIndex].timeRejected
        ) {
          history.transfers[keyIndex].timeUpdated = moment(
            history.transfers[keyIndex].timeRejected * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
      }

      history.transfers[
        keyIndex
      ].transferAmount = `${history.transfers[keyIndex].transferCurrency} ${history.transfers[keyIndex].transferAmount}`;

      dataTransfers.push(history.transfers[keyIndex]);
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              Historical requests
              {loading ? (
                <Spinner animation="border" size="sm" className="float-right" />
              ) : null}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey="deposits"
              transition={false}
              id="historical-requests-tabs"
            >
              <Tab eventKey="deposits" title="DEPOSITS" className="py-4">
                <BootstrapTable
                  bootstrap4
                  keyField="_id"
                  data={dataDeposits}
                  columns={columnsDeposits}
                  defaultSorted={defaultSorted}
                  hover={true}
                />
              </Tab>
              <Tab eventKey="withdrawals" title="WITHDRAWALS" className="py-4">
                <BootstrapTable
                  bootstrap4
                  keyField="_id"
                  data={dataWithdrawals}
                  columns={columnsWithdrawals}
                  defaultSorted={defaultSorted}
                  hover={true}
                />
              </Tab>
              <Tab
                eventKey="transfer"
                title="INTERNAL TRANSFERS"
                className="py-4"
              >
                <BootstrapTable
                  bootstrap4
                  keyField="_id"
                  data={dataTransfers}
                  columns={columnsTransfers}
                  defaultSorted={defaultSorted}
                  hover={true}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HistoricalRequests;
