import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import classes from "./Profile.module.css";
import {
  getAdmins,
  adminGetFundingRequests,
} from "../../../../redux/actions/adminActions";
import { capitalize } from "../../../../utils/utils";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const FundingHistory = props => {
  const [admins, setAdmins] = useState({});
  const [transferType, setTransferType] = useState("deposit");
  const [history, setHistory] = useState({});

  useEffect(() => {
    getAdmins()
      .then(res => {
        setAdmins(res.data.admins);
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    adminGetFundingRequests(props.userId, transferType, "approved")
      .then(res => {
        setHistory(res.data.transfers);
      })
      .catch(err => console.error(err));
  }, [transferType, props.userId]);

  const changeTransferType = type => {
    setTransferType(type);
  };

  let columnsFundingHistory = false;
  switch (transferType) {
    case "deposit":
      columnsFundingHistory = [
        {
          dataField: "timestamp",
          text: "DATE",
          classes: "breakWord",
        },
        {
          dataField: "transactionId",
          text: "TRANSACTION ID",
          classes: "breakWord",
        },
        {
          dataField: "gateway",
          text: "GATEWAY",
          classes: "breakWord",
        },
        {
          dataField: "depositCurrency",
          text: "BASE CURRENCY",
          classes: "breakWord",
        },
        {
          dataField: "depositAmountSent",
          text: "AMOUNT",
          classes: "text-right",
          headerStyle: (col, colIndex) => {
            return { textAlign: "right" };
          },
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            const numA = parseFloat(a);
            const numB = parseFloat(b);
            if (order === "asc") {
              return numB - numA;
            }
            return numA - numB; // desc
          },
        },
        // {
        //   dataField: "fee",
        //   text: "FEE",
        //   classes: "breakWord",
        //   headerStyle: (col, colIndex) => {
        //     return { textAlign: "right" };
        //   },
        //   sortFunc: (a, b, order, dataField, rowA, rowB) => {
        //     const numA = parseFloat(a);
        //     const numB = parseFloat(b);
        //     if (order === "asc") {
        //       return numB - numA;
        //     }
        //     return numA - numB; // desc
        //   },
        // },
        {
          dataField: "accountNumber",
          text: "FUNDED ACCOUNT",
          classes: "breakWord",
        },
        {
          dataField: "adminId",
          text: "PROCESSED BY",
          classes: "breakWord",
        },
        {
          dataField: "timeApproved",
          text: "PROCESSED TIME",
          classes: "breakWord",
        },
      ];
      break;
    case "withdrawal":
      columnsFundingHistory = [
        {
          dataField: "timestamp",
          text: "DATE",
          classes: "breakWord",
        },
        {
          dataField: "requestId",
          text: "TRANSACTION ID",
          classes: "breakWord",
        },
        {
          dataField: "withdrawalType",
          text: "GATEWAY",
          classes: "breakWord",
        },
        {
          dataField: "withdrawalCurrency",
          text: "BASE CURRENCY",
          classes: "breakWord",
        },
        {
          dataField: "withdrawalAmount",
          text: "AMOUNT",
          classes: "text-right",
          headerStyle: (col, colIndex) => {
            return { textAlign: "right" };
          },
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            const numA = parseFloat(a);
            const numB = parseFloat(b);
            if (order === "asc") {
              return numB - numA;
            }
            return numA - numB; // desc
          },
        },
        // {
        //   dataField: "fee",
        //   text: "FEE",
        //   classes: "breakWord",
        //   headerStyle: (col, colIndex) => {
        //     return { textAlign: "right" };
        //   },
        //   sortFunc: (a, b, order, dataField, rowA, rowB) => {
        //     const numA = parseFloat(a);
        //     const numB = parseFloat(b);
        //     if (order === "asc") {
        //       return numB - numA;
        //     }
        //     return numA - numB; // desc
        //   },
        // },
        {
          dataField: "accountNumber",
          text: "FUNDED ACCOUNT",
          classes: "breakWord",
        },
        {
          dataField: "adminId",
          text: "PROCESSED BY",
          classes: "breakWord",
        },
        {
          dataField: "timeApproved",
          text: "PROCESSED TIME",
          classes: "breakWord",
        },
      ];
      break;
    case "transfer":
      columnsFundingHistory = [
        {
          dataField: "timestamp",
          text: "DATE",
          classes: "breakWord",
        },
        {
          dataField: "_id",
          text: "TRANSACTION ID",
          classes: "breakWord",
        },
        {
          dataField: "transferCurrency",
          text: "BASE CURRENCY",
          classes: "breakWord",
        },
        {
          dataField: "transferAmount",
          text: "AMOUNT",
          classes: "text-right",
          headerStyle: (col, colIndex) => {
            return { textAlign: "right" };
          },
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            const numA = parseFloat(a);
            const numB = parseFloat(b);
            if (order === "asc") {
              return numB - numA;
            }
            return numA - numB; // desc
          },
        },
        // {
        //   dataField: "fee",
        //   text: "FEE",
        //   classes: "breakWord",
        //   headerStyle: (col, colIndex) => {
        //     return { textAlign: "right" };
        //   },
        //   sortFunc: (a, b, order, dataField, rowA, rowB) => {
        //     const numA = parseFloat(a);
        //     const numB = parseFloat(b);
        //     if (order === "asc") {
        //       return numB - numA;
        //     }
        //     return numA - numB; // desc
        //   },
        // },
        {
          dataField: "destinationAccount",
          text: "FUNDED ACCOUNT",
          classes: "breakWord",
        },
        {
          dataField: "sourceAccount",
          text: "FROM ACCOUNT",
          classes: "breakWord",
        },
        {
          dataField: "adminId",
          text: "PROCESSED BY",
          classes: "breakWord",
        },
        {
          dataField: "timeApproved",
          text: "PROCESSED TIME",
          classes: "breakWord",
        },
      ];
      break;
    default:
      return false;
  }

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
    ],
  });

  let dataFundingHistory = [];
  Object.keys(history).forEach((keyName, keyIndex) => {
    // Some data formatting before displaying
    if (Number.isInteger(history[keyIndex].timestamp)) {
      history[keyIndex].timestamp = moment(history[keyIndex].timestamp * 1000)
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();
    }

    switch (transferType) {
      case "deposit":
        if (history[keyIndex].depositType) {
          history[keyIndex].gateway = `${
            history[keyIndex].paymentGatewayService
          } - ${
            history[keyIndex].depositPaymentName ||
            capitalize(history[keyIndex].depositType.replace("_", " "))
          }`;
        }
        history[keyIndex].depositAmountSent = parseFloat(
          history[keyIndex].depositAmountSent
        ).toFixed(2);
        history[
          keyIndex
        ].accountNumber = `(${history[keyIndex].accountPlatform}) ${history[keyIndex].accountNumber}`;
        break;
      case "withdrawal":
        if (history[keyIndex].withdrawalType) {
          history[keyIndex].gateway = `${
            history[keyIndex].paymentGatewayService
          } - ${
            history[keyIndex].withdrawalPaymentName ||
            capitalize(history[keyIndex].withdrawalType.replace("_", " "))
          }`;
        }
        history[keyIndex].withdrawalAmount = parseFloat(
          history[keyIndex].withdrawalAmount
        ).toFixed(2);
        history[
          keyIndex
        ].accountNumber = `(${history[keyIndex].accountPlatform}) ${history[keyIndex].accountNumber}`;
        break;
      case "transfer":
        history[keyIndex].transferAmount = parseFloat(
          history[keyIndex].transferAmount
        ).toFixed(2);
        history[
          keyIndex
        ].sourceAccount = `(${history[keyIndex].destinationAccountPlatform}) ${history[keyIndex].sourceAccount}`;
        history[
          keyIndex
        ].destinationAccount = `(${history[keyIndex].destinationAccountPlatform}) ${history[keyIndex].destinationAccount}`;
        break;
      default:
        return false;
    }

    history[keyIndex].adminId = admins[history[keyIndex].adminId];

    history[keyIndex].timeApproved = moment(
      history[keyIndex].timeApproved * 1000
    )
      .format("YYYY/MM/DD HH:mm:ss")
      .toString();

    dataFundingHistory.push(history[keyIndex]);
  });

  return (
    <Card className={classes.card}>
      <Card.Body className="p-0 pb-2">
        <p
          className={[
            "p-3 m-0 border-bottom fontWeight500",
            classes.cardHeader,
          ].join(" ")}
        >
          Funding History
          <Button
            type="button"
            variant="link"
            className={[
              "ml-4",
              transferType === "deposit"
                ? "greenButton text-white"
                : "grayButton",
            ].join(" ")}
            onClick={() => changeTransferType("deposit")}
          >
            Deposits
          </Button>
          <Button
            type="button"
            variant="link"
            className={[
              "ml-3",
              transferType === "withdrawal"
                ? "greenButton text-white"
                : "grayButton",
            ].join(" ")}
            onClick={() => changeTransferType("withdrawal")}
          >
            Withdrawals
          </Button>
          <Button
            type="button"
            variant="link"
            className={[
              "ml-3",
              transferType === "transfer"
                ? "greenButton text-white"
                : "grayButton",
            ].join(" ")}
            onClick={() => changeTransferType("transfer")}
          >
            Internal Transfers
          </Button>
        </p>

        <BootstrapTable
          bootstrap4
          bordered={false}
          keyField="_id"
          data={dataFundingHistory}
          columns={columnsFundingHistory}
          pagination={pagination}
          hover={true}
          classes={classes.table}
          headerClasses={classes.tableHeaderClass}
        />
      </Card.Body>
    </Card>
  );
};

export default FundingHistory;
