import React, { useState, useEffect, useRef } from "react";
import classes from "./Profile.module.css";
import {
  getUserLogs,
  adminAddLog,
} from "../../../../redux/actions/adminActions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Card, Form, Modal } from "react-bootstrap";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import { NotificationManager } from "react-notifications";

const ClientLog = props => {
  const [logs, setLogs] = useState({});
  const [state, setState] = useState({
    disableBtn: false,
    showLogModal: false,
  });
  const inputLog = useRef("");

  useEffect(() => {
    triggerGetUserLogs();
  }, [props.userId]);

  const triggerGetUserLogs = () => {
    getUserLogs(props.userId)
      .then(res => {
        setLogs(res.data);
      })
      .catch(err => console.log(err));
  };

  const handleAddLog = event => {
    if (event === "") {
      NotificationManager.error("Event is required.", "Error", 5000);
      return false;
    }

    adminAddLog({ userId: props.userId, event })
      .then(res => {
        if (res.data.success) {
          handleCloseModal();
          triggerGetUserLogs();
        } else
          NotificationManager.error("Something went wrong!", "Error", 5000);
      })
      .catch(err => {
        NotificationManager.error("Something went wrong!", "Error", 5000);
        console.error(err);
      });
  };

  const handleShowModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showLogModal: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showLogModal: false,
      };
    });
  };

  const columnsClientLog = [
    {
      dataField: "timestamp",
      text: "DATE",
      classes: "breakWord",
    },
    {
      dataField: "event",
      text: "EVENT",
      classes: "breakWord",
    },
    {
      dataField: "actionedBy",
      text: "ACTIONED BY",
      classes: "breakWord",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
    ],
  });

  let dataClientLog = [];
  if (!!logs) {
    for (let i = 0; i < logs.length; i++) {
      if (Number.isInteger(logs[i].timestamp)) {
        logs[i].timestamp = moment(logs[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (logs[i].customName) logs[i].actionedBy = logs[i].customName;

      dataClientLog.push(logs[i]);
    }
  }

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0 pb-2">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Client Log
            <Button
              type="button"
              variant="link"
              className="smallGreenButton mt-1 text-white"
              onClick={handleShowModal}
            >
              + ADD
            </Button>
          </p>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={dataClientLog}
            columns={columnsClientLog}
            hover={true}
            pagination={pagination}
            classes={classes.table}
            headerClasses={classes.tableHeaderClass}
          />
        </Card.Body>
      </Card>

      <Modal show={state.showLogModal} onHide={handleCloseModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Add log entry</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Control type="text" ref={inputLog} />
          </Form.Group>

          <Form.Row className="justify-content-between">
            <Button
              className="statusFlag statusFlagMedium statusFlagApprovedMedium border-0 text-dark fontWeight500 mx-4"
              disabled={state.disableBtn}
              onClick={() => handleAddLog(inputLog.current.value)}
            >
              <img src={IconApproved} alt="" /> Submit
            </Button>

            <Button
              className="statusFlag statusFlagMedium statusFlagRejected border-0 text-dark fontWeight500 mx-4"
              disabled={state.disableBtn}
              onClick={handleCloseModal}
            >
              <img src={IconRejected} alt="" /> Discard
            </Button>
          </Form.Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClientLog;
