import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import classes from "./Profile.module.css";
import {
  getAdmins,
  adminGetAccounts,
} from "../../../../redux/actions/adminActions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";

const TradingAccounts = props => {
  const [admins, setAdmins] = useState({});
  const [accounts, setAccounts] = useState({});

  useEffect(() => {
    getAdmins()
      .then(res => {
        setAdmins(res.data.admins);
      })
      .catch(err => console.error(err));

    adminGetAccounts(props.userId, "live", "approved")
      .then(res => {
        setAccounts(res.data);
      })
      .catch(err => console.log(err));
  }, [props.userId]);

  const columnsTradingAccounts = [
    {
      dataField: "number",
      text: "ACCOUNT NUMBER",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "platform",
      text: "PLATFORM",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "managerGroup",
      text: "GROUP",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "baseCurrency",
      text: "BASE CURRENCY",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "leverageText",
      text: "LEVERAGE",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "timeCreated",
      text: "DATE OPENED",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "adminId",
      text: "APPROVED BY",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "balance",
      text: "BALANCE",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
    {
      dataField: "equity",
      text: "EQUITY",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
  ];

  let dataTradingAccounts = [];
  if (Object.keys(accounts).length > 0) {
    accounts.forEach((keyName, keyIndex) => {
      if (Number.isInteger(accounts[keyIndex].timeCreated)) {
        accounts[keyIndex].timeCreated = moment(
          accounts[keyIndex].timeCreated * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      accounts[keyIndex].leverageText = `1:${accounts[keyIndex].leverage}`;

      if (admins[accounts[keyIndex].adminId]) {
        accounts[keyIndex].adminId = admins[accounts[keyIndex].adminId];
      } else {
        accounts[keyIndex].adminId = "AUTOMATION";
      }

      accounts[keyIndex].balance = parseFloat(
        accounts[keyIndex].balance
      ).toFixed(2);
      accounts[keyIndex].equity = parseFloat(accounts[keyIndex].equity).toFixed(
        2
      );

      dataTradingAccounts.push(accounts[keyIndex]);
    });
  }

  return (
    <Card className={classes.card}>
      <Card.Body className="p-0">
        <p
          className={[
            "p-3 m-0 border-bottom fontWeight500",
            classes.cardHeader,
          ].join(" ")}
        >
          Trading Accounts
        </p>
        <BootstrapTable
          bootstrap4
          bordered={false}
          keyField="_id"
          data={dataTradingAccounts}
          columns={columnsTradingAccounts}
          hover={true}
          classes={classes.table}
          headerClasses={classes.tableHeaderClass}
        />
      </Card.Body>
    </Card>
  );
};

export default TradingAccounts;
