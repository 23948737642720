import fundingMethodBank from "../assets/images/funding-methods/fundingMethodBank.gif";
import fundingMethodCrypto from "../assets/images/funding-methods/fundingMethodCrypto.gif";
import fundingMethodIdeal from "../assets/images/funding-methods/fundingMethodIdeal.gif";
import fundingMethodNeteller from "../assets/images/funding-methods/fundingMethodNeteller.gif";
import fundingMethodSkrill from "../assets/images/funding-methods/fundingMethodSkrill.gif";
import fundingMethodSticpay from "../assets/images/funding-methods/fundingMethodSticpay.gif";
import fundingMethodVisaMC from "../assets/images/funding-methods/fundingMethodVisaMC.gif";

export const API_URL = "https://secure.iquoto.com:5000";
// export const API_URL = "https://localhost:5000";

export const SUMSUB_API_URL = "https://test-api.sumsub.com";

export const CLIENT_ADMIN_URL = "https://secure.iquoto.com";
export const WEBSITE_URL = "https://iquoto.com";

export const encryptionKey = "SigurnoNeZnas!ukportal#";

export const paymentMethodData = [
  {
    id: "credit_card",
    paymentGateway: "ZP",
    method: "Credit/Debit Card",
    time: "Instant",
    fees: "0%",
    image: fundingMethodVisaMC,
    text: "Fund using VISA or Mastercard Credit or Debit Cards",
  },
  {
    id: "wire",
    paymentGateway: "WIRE",
    method: "Bank Transfer",
    time: "2-5 Days",
    fees: "0%",
    image: fundingMethodBank,
    text: "Fund using traditional bank transfer to our secure segregated bank account",
  },
  {
    id: "skrill",
    paymentGateway: "PS",
    method: "Skrill",
    time: "Instant",
    fees: "0%",
    image: fundingMethodSkrill,
    text: "Fund instantly with your Skrill account",
  },
  {
    id: "neteller",
    paymentGateway: "PS",
    method: "Neteller",
    time: "Instant",
    fees: "0%",
    image: fundingMethodNeteller,
    text: "Fund instantly with your Neteller account",
  },
  {
    id: "ideal",
    paymentGateway: "SC",
    method: "iDEAL",
    time: "Instant",
    fees: "0%",
    image: fundingMethodIdeal,
    text: "Fund instantly with your iDEAL account",
  },
  // {
  //   id: "crypto",
  //   method: "Crypto Payment",
  //   time: "1-2 Hours",
  //   fees: "0%",
  //   image: fundingMethodCrypto,
  //   text:
  //     "Fund safely and securely using Bitcoin, Ethereum, Ripple or Litecoin",
  // },
  // {
  //   id: "sticpay",
  //   method: "STICPAY",
  //   time: "Instant",
  //   fees: "0%",
  //   image: fundingMethodSticpay,
  //   text: "Fund instantly with your STICPAY account",
  // },
];

export const withdrawalMethodData = [
  {
    id: "credit_card",
    paymentGateway: "ZP",
    method: "Credit/Debit Card",
    time: "3-5 Days",
    fees: "0%",
    image: fundingMethodVisaMC,
  },
  {
    id: "wire",
    paymentGateway: "WIRE",
    method: "Bank Transfer",
    time: "2-3 Days",
    fees: "0%",
    image: fundingMethodBank,
  },
  {
    id: "skrill",
    paymentGateway: "PS",
    method: "Skrill",
    time: "Instant",
    fees: "0%",
    image: fundingMethodSkrill,
  },
  {
    id: "neteller",
    paymentGateway: "PS",
    method: "Neteller",
    time: "Instant",
    fees: "0%",
    image: fundingMethodNeteller,
  },
  {
    id: "ideal",
    paymentGateway: "SC",
    method: "iDEAL",
    time: "Instant",
    fees: "0%",
    image: fundingMethodIdeal,
  },
  // {
  //   id: "crypto",
  //   method: "Crypto Payment",
  //   time: "1-2 Hours",
  //   fees: "0%",
  //   image: fundingMethodCrypto,
  // },
  // {
  //   id: "sticpay",
  //   method: "STICPAY",
  //   time: "Instant",
  //   fees: "0%",
  //   image: fundingMethodSticpay,
  // },
];
