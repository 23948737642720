import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  adminGetLeverageRequests,
  adminUpdateAccountLeverage,
  adminProcessLeverageUpdate,
} from "../../../redux/actions/adminActions";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import { getRequestStatusSelectListOptions } from "../../../utils/utils";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import { filterAccountType } from "../../../config/constants";
import { NotificationManager } from "react-notifications";

const ChangeLeverageRequests = props => {
  const [state, setState] = useState({
    editLeverageErrors: "",
    showLeverageModal: false,
    disableEditLeverageBtn: false,
    selectedLeverage: {},
    loading: true,
  });
  const [changeLeverageRequests, setLeverageRequests] = useState(null);

  const { status } = props.match.params;

  useEffect(() => {
    triggerAdminGetLeverageRequests();
  }, [status]);

  const triggerAdminGetLeverageRequests = () => {
    setLeverageRequests([]);
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    adminGetLeverageRequests(status)
      .then(res => {
        setLeverageRequests(res.data.leverages);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.error(err));
  };

  const handleUpdateLeverage = e => {
    let name = e.target.name;
    let value = e.target.value;

    const selectedLeverage = state.selectedLeverage;
    selectedLeverage[name] = value;
    setState(prevState => {
      return { ...prevState, selectedLeverage };
    });
  };

  const handleRejectLeverageChange = leverage => {
    leverage.status = "rejected";
    setState(prevState => {
      return {
        ...prevState,
        showLeverageModal: true,
        selectedLeverage: leverage,
      };
    });
  };

  const handleMarkProcessed = (changeLeverageId, status) => {
    if (
      window.confirm(`Do you want to mark this leverage change as ${status}?`)
    ) {
      adminProcessLeverageUpdate(changeLeverageId, status)
        .then(res => {
          if (res.data.success) {
            createNotification("success");
            handleCloseModal();
            triggerAdminGetLeverageRequests();
          } else {
            createNotification("error");

            setState(prevState => {
              return {
                ...prevState,
                editLeverageErrors: res.data.errors,
              };
            });
          }
        })
        .catch(err => console.error(err));
    }
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const selectedLeverage = state.selectedLeverage;

    if (
      selectedLeverage.status === "rejected" &&
      (!selectedLeverage.comment || selectedLeverage.comment.trim() === "")
    ) {
      alert("You cannot reject leverage change without a reason");
      return false;
    }

    setState(prevState => {
      return { ...prevState, disableEditLeverageBtn: true };
    });

    delete selectedLeverage.approve;
    delete selectedLeverage.reject;

    if (form.checkValidity()) {
      adminUpdateAccountLeverage(selectedLeverage)
        .then(res => {
          if (res.data.success) {
            createNotification("success");
            handleCloseModal();
            triggerAdminGetLeverageRequests();
          } else {
            createNotification("error");

            setState(prevState => {
              return {
                ...prevState,
                editLeverageErrors: res.data.errors,
              };
            });
          }
        })
        .catch(err => console.error(err));
    }
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        disableEditLeverageBtn: false,
        showLeverageModal: false,
      };
    });
  };

  const createNotification = type => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the leverage change request.",
          "Account Update",
          5000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong!", "Error", 5000);
        break;
      default:
    }
  };

  const customFilterAccountType = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.accountType === filterVal);
    }
    return data;
  };

  const columns = [
    {
      dataField: "accountNumber",
      text: "ACCOUNT",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "accountType",
      text: "TYPE",
      sort: true,
      filter: selectFilter({
        options: filterAccountType,
        onFilter: customFilterAccountType,
      }),
    },
    {
      dataField: "oldLeverage",
      text: "OLD LEVERAGE",
      sort: true,
    },
    {
      dataField: "newLeverage",
      text: "NEW LEVERAGE",
      sort: true,
    },
    {
      dataField: "timestamp",
      text: "REQUESTED",
      sort: true,
    },
  ];
  if (status === "pending") {
    columns.push(
      {
        dataField: "reject",
        text: "",
        classes: "text-right",
      },
      {
        dataField: "approve",
        text: "",
      }
    );
  }

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "asc",
    },
  ];

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setState(prevState => {
        return { ...prevState, showLeverageModal: true, selectedLeverage: row };
      });
    },
  };

  let data = [];
  if (!!changeLeverageRequests) {
    changeLeverageRequests.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(changeLeverageRequests[keyIndex].timestamp)) {
        changeLeverageRequests[keyIndex].timestamp = moment(
          changeLeverageRequests[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (
        !changeLeverageRequests[keyIndex].oldLeverage.toString().includes(":")
      ) {
        changeLeverageRequests[
          keyIndex
        ].oldLeverage = `1:${changeLeverageRequests[keyIndex].oldLeverage}`;
      }

      if (
        !changeLeverageRequests[keyIndex].newLeverage.toString().includes(":")
      ) {
        changeLeverageRequests[
          keyIndex
        ].newLeverage = `1:${changeLeverageRequests[keyIndex].newLeverage}`;
      }

      if (status === "pending") {
        changeLeverageRequests[keyIndex].reject = (
          <Button
            type="button"
            variant="link"
            className="redButton text-white"
            onClick={() =>
              handleRejectLeverageChange(changeLeverageRequests[keyIndex])
            }
          >
            REJECT
          </Button>
        );
        changeLeverageRequests[keyIndex].approve = (
          <Button
            type="button"
            variant="link"
            className="blueButton text-white"
            onClick={() =>
              handleMarkProcessed(
                changeLeverageRequests[keyIndex]._id,
                "approved"
              )
            }
          >
            APPROVE
          </Button>
        );
      }

      data.push(changeLeverageRequests[keyIndex]);
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="pt-3 px-3">
            Change leverage requests -{" "}
            <span className="text-capitalize">{status}</span>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                status === "pending" ? "disabled" : "",
              ]}
              as={Link}
              to={"/change-leverage-requests/pending"}
            >
              Pending
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                status === "approved" ? "disabled" : "",
              ]}
              as={Link}
              to={"/change-leverage-requests/approved"}
            >
              Approved
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                status === "rejected" ? "disabled" : "",
              ]}
              as={Link}
              to={"/change-leverage-requests/rejected"}
            >
              Rejected
            </Button>
            {state.loading ? (
              <Spinner animation="border" size="sm" className="float-right" />
            ) : null}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <BootstrapTable
            bootstrap4
            keyField="_id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            hover={true}
            filter={filterFactory()}
            rowEvents={rowEvents}
            headerClasses="tableHeaderClass"
          />

          <Modal
            show={state.showLeverageModal}
            onHide={handleCloseModal}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update change leverage</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={e => handleSubmit(e)}>
                <Form.Group>
                  <Form.Label>Acc. number</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedLeverage.accountNumber}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {state.editLeverageErrors.accountNumber}
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedLeverage.accountType}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="comment"
                    onChange={e => handleUpdateLeverage(e)}
                    defaultValue={state.selectedLeverage.comment}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    required
                    onChange={e => handleUpdateLeverage(e)}
                    defaultValue={state.selectedLeverage.status}
                    disabled={status !== "pending"}
                  >
                    <option value="">Choose leverage change status</option>
                    {getRequestStatusSelectListOptions()}
                  </Form.Control>
                </Form.Group>

                <Form.Text style={{ color: "red" }}>
                  {state.editLeverageErrors.errors ||
                    state.editLeverageErrors.server}
                  <br />
                  <br />
                </Form.Text>

                <Form.Row className="justify-content-between">
                  <Button
                    variant="secondary"
                    className="greyButton"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    className="darkBlueButton"
                    style={{ fontWeight: "normal" }}
                    disabled={state.disableEditLeverageBtn}
                  >
                    {state.disableEditLeverageBtn ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Update
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Form.Row>
              </Form>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangeLeverageRequests;
