import React, { useEffect, useState } from "react";
import { getUser } from "../../../../redux/actions/adminActions";
import { Col, Container, Row } from "react-bootstrap";
import ProfileBar from "./ProfileBar";
import ProfessionalApplication from "./ProfessionalApplication";
import KYCDocuments from "./KYCDocuments";
import Compliance from "./Compliance";
import ClientLog from "./ClientLog";
import ProfessionalClientStatus from "./ProfessionalClientStatus";

const ProfessionalClientApplication = props => {
  const [user, setUser] = useState(false);

  useEffect(() => {
    getUser(props.match.params.id)
      .then(res => {
        setUser(res.data.user);
      })
      .catch(err => console.log(err));
  }, [props.match.params.id]);

  return (
    <Container fluid>
      {user ? (
        <Row>
          <Col>
            <ProfileBar userData={user} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md="8">
          <ProfessionalApplication userId={props.match.params.id} />
          {user ? <KYCDocuments userData={user} /> : null}
        </Col>
        <Col md="4">
          <ClientLog userId={props.match.params.id} />
          <Compliance userId={props.match.params.id} validate={true} />
          {user ? <ProfessionalClientStatus user={user} /> : null}
        </Col>
      </Row>
    </Container>
  );
};

export default ProfessionalClientApplication;
