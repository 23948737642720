import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

class AdminDashboard extends Component {
  render() {
    return (
      <>
        <Container>
          <Row className="justify-content-lg-center">
            <Col>
              <h1>Admin Dashboard</h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-lg-center">
            <Col>test</Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AdminDashboard;
