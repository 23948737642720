import axios from "axios";
import store from "../../redux/store";
import { GET_ERRORS, GET_SUCCESS, SET_MENU_COUNTERS } from "./types";
import { API_URL } from "../../config/config";

// Get admins
export const getAdmins = () =>
  axios.get(API_URL + "/api/admin/users/getAdmins");

// Get user
export const getUser = id =>
  axios.get(API_URL + "/api/admin/users/profile/" + id);

// Get all users
export const getUsers = (status, limit) =>
  axios.get(API_URL + "/api/admin/users/list/" + status + "/" + limit);

// Get user logs
export const getUserLogs = id =>
  axios.get(`${API_URL}/api/admin/users/${id}/logs`);

// Get Client Application Data
export const getClientApplicationData = id =>
  axios.get(`${API_URL}/api/admin/users/${id}/getClientApplicationData`);

// Get Client Application Data
export const getKYCApprovedStatus = id =>
  axios.get(API_URL + "/api/admin/users/getKYCApprovedStatus/" + id);

// Get Client Application Documents
export const getKYCDocuments = id =>
  axios.get(API_URL + "/api/admin/users/getKYCDocuments/" + id);

// Get new requests count
export const getNewRequestsCount = () =>
  axios.get(API_URL + "/api/other/getNewRequestsCount/");

// Set new clients
export const setMenuCounters = counters => {
  return {
    type: SET_MENU_COUNTERS,
    payload: counters,
  };
};

// Add user
export const adminAddUser = (userData, history) => dispatch => {
  axios
    .post(API_URL + "/api/admin/users/register", userData)
    .then(res => history.push("/admin/users"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit user
export const adminEditUser = userData =>
  axios.post(API_URL + "/api/admin/users/editUser", userData);

// Process user
export const adminProcessUser = userData =>
  axios.post(API_URL + "/api/admin/users/processUser", userData);

// Get professional clients
export const getProfessionalClients = (status, limit) =>
  axios.get(
    API_URL + "/api/admin/users/list/professionals/" + status + "/" + limit
  );

// Process user
export const adminProcessProfClientApplication = (userId, status) =>
  axios.post(API_URL + "/api/admin/users/processUserProfClientApplication", {
    userId,
    status,
  });

// Update Professional Client Status
export const adminUpdateProfessionalClientStatus = userData =>
  axios.post(
    API_URL + "/api/admin/users/updateProfessionalClientStatus",
    userData
  );

// Get all accounts by userId, type, status
export const adminGetProfessionalClientApplication = userId =>
  axios.get(
    `${API_URL}/api/admin/users/${userId}/getProfessionalClientApplication`
  );

// Process user
export const adminAddLog = logData =>
  axios.post(API_URL + "/api/admin/users/addLog", logData);

// Get all accounts by userId, type, status
export const adminGetAccounts = (userId, type, status) =>
  axios.get(
    `${API_URL}/api/admin/accounts/adminGetAccounts/${userId}/${type}/${status}`
  );

// Admin update account
export const adminUpdateAccount = account =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateAccount", account);

// Get bank account details
export const adminGetBankAccount = bankId =>
  axios.get(API_URL + "/api/admin/accounts/adminGetBankAccount/" + bankId);

// Get all pending verification bank details
export const adminGetBankAccounts = (userId, status) =>
  axios.get(
    `${API_URL}/api/admin/accounts/adminGetBankAccounts/${userId}/${status}`
  );

// Admin update bank account
export const adminUpdateBankAccount = account =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateBankAccount", account);

// Get all deposits by status
export const adminGetFundingRequests = (userId, type, status) =>
  axios.get(
    `${API_URL}/api/admin/accounts/adminGetFundingRequests/${userId}/${type}/${status}`
  );

// Admin update deposit
export const adminUpdateDeposit = deposit =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateDeposit", {
    deposit,
  });

// Admin request bank details for a deposit
export const adminDepositReqBankDetails = depositId =>
  axios.post(API_URL + "/api/admin/accounts/adminDepositReqBankDetails", {
    depositId,
  });

// Admin update deposit
export const adminProcessDeposit = depositId =>
  axios.post(API_URL + "/api/admin/accounts/adminProcessDeposit", {
    depositId,
  });

// Admin update transfer
export const adminUpdateTransfer = transfer =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateTransfer", {
    transfer,
  });

// Admin update transfer
export const adminProcessTransfer = transferId =>
  axios.post(API_URL + "/api/admin/accounts/adminProcessTransfer", {
    transferId,
  });

// Admin update withdrawal
export const adminUpdateWithdrawal = withdrawal =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateWithdrawal", {
    withdrawal,
  });

// Admin update withdrawal
export const adminProcessWithdrawal = withdrawalId =>
  axios.post(API_URL + "/api/admin/accounts/adminProcessWithdrawal", {
    withdrawalId,
  });

// Admin update withdrawal - ZotaPay
export const adminUpdateWithdrawalZotaPay = withdrawal =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateWithdrawalZotaPay", {
    withdrawal,
  });

// Admin update withdrawal - ZotaPay
export const adminProcessWithdrawalZotaPay = withdrawalId =>
  axios.post(API_URL + "/api/admin/accounts/adminProcessWithdrawalZotaPay", {
    withdrawalId,
  });

// Get all leverage requests
export const adminGetLeverageRequests = status =>
  axios.get(API_URL + "/api/admin/accounts/adminGetLeverageRequests/" + status);

// Admin update leverage request
export const adminUpdateAccountLeverage = account =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateAccountLeverage", {
    account,
  });

// Admin update leverage
export const adminProcessLeverageUpdate = (changeLeverageId, status) =>
  axios.post(API_URL + "/api/admin/accounts/adminProcessLeverageUpdate", {
    changeLeverageId,
    status,
  });

// Admin update close account request
export const adminUpdateCloseAccount = accountId => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminUpdateCloseAccount", {
      accountId,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin update close account request
export const adminProcessCloseAccountUpdate = accountId => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminProcessCloseAccountUpdate", {
      accountId,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get transfer history
export const getTransferHistory = () =>
  axios.get(API_URL + "/api/admin/accounts/getTransferHistory");

// Get groups
export const getGroups = () =>
  axios.get(API_URL + "/api/admin/groups/getGroups");

// Add group
export const adminAddGroup = groupData => dispatch => {
  axios
    .post(API_URL + "/api/admin/groups/addGroup", groupData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit group
export const adminEditGroup = groupData => dispatch => {
  axios
    .post(API_URL + "/api/admin/groups/editGroup", groupData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Delete group
export const adminDeleteGroup = groupData => dispatch => {
  axios
    .post(API_URL + "/api/admin/groups/deleteGroup", groupData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get all IB Codes
export const adminGetIbCodes = () =>
  axios.get(`${API_URL}/api/admin/users/adminGetIbCodes`);

// Get next IB Code
export const adminGetNextIbCode = () =>
  axios.get(`${API_URL}/api/admin/users/adminGetNextIbCode`);

// Update IB Code
export const adminUpdateIbCode = ibCodeId =>
  axios.post(API_URL + "/api/admin/users/adminUpdateIbCode", ibCodeId);

// Generate Codes
export const adminGenerateCodes = data =>
  axios.post(API_URL + "/api/admin/users/adminGenerateCodes", data);

// Get Generated Codes
export const adminGetGeneratedCodes = agentCode =>
  axios.get(API_URL + "/api/admin/users/adminGetGeneratedCodes/" + agentCode);

// Get Email Notifications
export const adminGetEmailNotifications = userId =>
  axios.get(API_URL + "/api/admin/partners/getEmailNotifications/" + userId);

// Update Email Notifications
export const adminUpdateEmailNotifications = settings =>
  axios.post(
    API_URL + "/api/admin/partners/updateEmailNotifications",
    settings
  );

// Get ML statuses
export const adminGetMlStatuses = () =>
  axios.get(API_URL + "/api/admin/other/getMlStatuses/");

// Clear success/error props
export const clearSuccessErrorProps = () => {
  store.dispatch({
    type: GET_SUCCESS,
    payload: {},
  });
  store.dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};
