import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { adminProcessUser } from "../../../../redux/actions/adminActions";
import classes from "./Profile.module.css";
import IconPending from "../../../../assets/images/icon-pending.svg";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import { NotificationManager } from "react-notifications";

const Compliance = props => {
  const inputReason = useRef("");
  const [state, setState] = useState({
    disableBtn: false,
    showReasonModal: false,
    errors: "",
  });

  const handleProcessUser = (status, reason) => {
    if (status === "pending" && reason === "") {
      alert("Reason is required.");
      return false;
    }

    if (
      window.confirm(
        `Are you sure you want to change user status to ${status}?`
      )
    ) {
      setState(prevState => {
        return {
          ...prevState,
          disable: true,
        };
      });
      adminProcessUser({ userId: props.userId, status, reason })
        .then(res => {
          if (res.data.success) {
            window.location.reload();
          } else {
            NotificationManager.error(res.data.error, "", 5000);
          }
        })
        .catch(err => console.log(err));
    }
  };

  const handleShowModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showReasonModal: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showReasonModal: false,
      };
    });
  };

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Compliance
          </p>

          {/*<div className="border-bottom pt-2 px-3">*/}
          {/*  <p className={classes.complianceText}>Age 18 Plus:</p>*/}
          {/*  <p className={classes.complianceText}>PEP Check:</p>*/}
          {/*  <p className={classes.complianceText}>Sanctions Check:</p>*/}
          {/*  <p className={classes.complianceText}>eKYC Check:</p>*/}
          {/*  <p className={classes.complianceText}>Declarations/Legals:</p>*/}
          {/*  <p className={classes.complianceText}>*/}
          {/*    Trading Experience/Knowledge:*/}
          {/*  </p>*/}
          {/*</div>*/}

          <Form.Text style={{ color: "red" }} className="my-1 pl-3">
            {state.error}
            <br />
          </Form.Text>

          {props.validate ? (
            <>
              <Button
                className="statusFlag statusFlagMedium statusFlagApprovedMedium border-0 text-dark fontWeight500 mx-4"
                disabled={state.disableBtn}
                onClick={() => handleProcessUser("approved", "")}
              >
                <img src={IconApproved} alt="" /> Approve
              </Button>
              <Button
                className="statusFlag statusFlagMedium statusFlagPending border-0 text-dark fontWeight500 mx-4"
                disabled={state.disableBtn}
                onClick={handleShowModal}
              >
                <img src={IconPending} alt="" /> Pending
              </Button>
              <Button
                className="statusFlag statusFlagMedium statusFlagRejected border-0 text-dark fontWeight500 mx-4"
                disabled={state.disableBtn}
                onClick={() => handleProcessUser("rejected", "")}
              >
                <img src={IconRejected} alt="" /> Reject
              </Button>
            </>
          ) : (
            <div className="px-3">
              <Button
                type="button"
                variant="link"
                as={Link}
                to={`/user/application/${props.userId}`}
                className={[
                  classes.complianceText,
                  "d-block p-0 mb-3 text-left text-dark textDecorationUnderline",
                ].join(" ")}
              >
                View Client Application
              </Button>

              {/*<Button*/}
              {/*  type="button"*/}
              {/*  variant="link"*/}
              {/*  as={Link}*/}
              {/*  to={`/user/documents/${props.userId}`}*/}
              {/*  className={[*/}
              {/*    classes.complianceText,*/}
              {/*    "d-block p-0 mb-3 text-left text-dark textDecorationUnderline",*/}
              {/*  ].join(" ")}*/}
              {/*>*/}
              {/*  View Client Documents*/}
              {/*</Button>*/}
            </div>
          )}
        </Card.Body>
      </Card>

      <Modal show={state.showReasonModal} onHide={handleCloseModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Reason</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Control type="text" ref={inputReason} />
          </Form.Group>

          <Form.Row className="justify-content-between">
            <Button
              className="statusFlag statusFlagMedium statusFlagApprovedMedium border-0 text-dark fontWeight500 mx-4"
              disabled={state.disableBtn}
              onClick={() =>
                handleProcessUser("pending", inputReason.current.value)
              }
            >
              <img src={IconApproved} alt="" /> Submit
            </Button>

            <Button
              className="statusFlag statusFlagMedium statusFlagRejected border-0 text-dark fontWeight500 mx-4"
              disabled={state.disableBtn}
              onClick={handleCloseModal}
            >
              <img src={IconRejected} alt="" /> Discard
            </Button>
          </Form.Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Compliance;
