import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userRoles, countryList } from "../../../../config/constants";
import {
  getUserRolesSelectListOptions,
  getUserStatusSelectListOptions,
  countryListReactSelect,
  getBirthDaysReactSelect,
  getBirthMonthsReactSelect,
  getBirthYearsReactSelect,
  encryptData,
} from "../../../../utils/utils";
import {
  getProfessionalClients,
  adminEditUser,
  adminGetEmailNotifications,
  adminUpdateEmailNotifications,
  adminProcessProfClientApplication,
} from "../../../../redux/actions/adminActions";
import classes from "./UserList.module.css";
import { FiAlertTriangle } from "react-icons/fi";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Form,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import IconPending from "../../../../assets/images/icon-pending.svg";
import IconPendingYellow from "../../../../assets/images/icon-pending-yellow.svg";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import IconNewClient from "../../../../assets/images/icon-new-client.svg";
import { FaCrown } from "react-icons/fa";
import { NotificationManager } from "react-notifications";

const ProfessionalClients = props => {
  const [state, setState] = useState({
    users: {},
    // fxlUsers: {},
    // showUserStatus: "",
    selectedUser: {},
    loadingUsers: true,
    disableBtn: false,
    disableRegisterApiBtn: false,
    editingUser: false,
    validated: false,
    errors: {},
  });
  const [emailNotifications, setEmailNotifications] = useState(false);

  useEffect(() => {
    triggerLoadUsers();

    const userLoader = setInterval(() => triggerLoadUsers(true), 30000);

    // Clean up setInterval
    return () => {
      clearInterval(userLoader);
    };
  }, [props.match.params.status]);

  const triggerLoadUsers = (auto = false) => {
    setState(prevState => {
      return auto
        ? {
            ...prevState,
            loadingUsers: true,
          }
        : {
            ...prevState,
            users: [],
            loadingUsers: true,
          };
    });
    getProfessionalClients(props.match.params.status, 0)
      .then(res => {
        if (res.data.success)
          setState(prevState => {
            return {
              ...prevState,
              users: res.data.clients,
              // fxlUsers: res.data.fxlUsers,
              loadingUsers: false,
            };
          });
        else createNotification("error", res.data.error);
      })
      .catch(err => {
        createNotification("error");
        console.log(err);
      });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        editingUser: false,
        disableBtn: false,
      };
    });
  };

  const handleChangeProfile = (e, target) => {
    let name;
    let value;

    if (
      target === "country" ||
      target === "nationality" ||
      target === "birthDay" ||
      target === "birthMonth" ||
      target === "birthYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "emailVerified") {
      value = e.target.checked;
    }

    const { selectedUser } = state;
    selectedUser[name] = value;
    setState(prevState => {
      return {
        ...prevState,
        selectedUser,
      };
    });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const { selectedUser } = state;

    if (
      (userRoles[props.auth.user.role] < userRoles["SYSOP"] &&
        selectedUser.role === "SYSOP") ||
      userRoles[selectedUser.role] === userRoles["ADMIN"]
    ) {
      return false;
    }

    setState(prevState => {
      return {
        ...prevState,
        disableBtn: true,
      };
    });

    if (form.checkValidity()) {
      const currentUser = { ...selectedUser };
      currentUser.status =
        userRoles[selectedUser.role] >= userRoles["ADMIN"]
          ? "approved"
          : selectedUser.status;

      if (props.auth.user.role === "SYSOP") {
        currentUser.role = selectedUser.role;
      }

      delete currentUser.userStatus;
      delete currentUser.eKYCApprovedIcon;
      delete currentUser.professionalClient;
      delete currentUser.completedApplication;
      delete currentUser.profile;

      if (currentUser.password)
        currentUser.password = encryptData(currentUser.password);
      if (currentUser.password2)
        currentUser.password2 = encryptData(currentUser.password2);

      adminEditUser(currentUser)
        .then(res => {
          handleCloseModal();
          triggerLoadUsers();

          if (res.data.success) createNotification("success");
          else createNotification("error", res.data.error);

          setState(prevState => {
            return {
              ...prevState,
              disableBtn: false,
            };
          });
        })
        .catch(err => {
          createNotification("error");
          console.error(err);

          setState(prevState => {
            return {
              ...prevState,
              disableBtn: false,
            };
          });
        });
    }
  };

  const handleSubmitEmailNotifications = event => {
    event.preventDefault();
    event.stopPropagation();

    const settings = {
      partnerId: state.selectedUser._id,
      agentCode: state.selectedUser.agentCode,
      partnerUserRegistered: document
        .getElementById("partnerUserRegistered")
        .value.trim(),
      partnerUserApproved: document
        .getElementById("partnerUserApproved")
        .value.trim(),
      partnerUserKYCApproved: document
        .getElementById("partnerUserKYCApproved")
        .value.trim(),
      partnerUserDeposited: document
        .getElementById("partnerUserDeposited")
        .value.trim(),
      // partnerUserRequestedWireTransfer: document
      //   .getElementById("partnerUserRequestedWireTransfer")
      //   .value.trim(),
      partnerUserWithdrawalRequest: document
        .getElementById("partnerUserWithdrawalRequest")
        .value.trim(),
    };

    setState(prevState => {
      return {
        ...prevState,
        disableBtn: true,
      };
    });
    adminUpdateEmailNotifications(settings)
      .then(res => {
        handleCloseModal();
        triggerLoadUsers();

        if (res.data.success) createNotification("successNotif");
        else createNotification("error", res.data.error);

        setState(prevState => {
          return {
            ...prevState,
            disableBtn: false,
          };
        });
      })
      .catch(err => {
        createNotification("error");
        console.error(err);

        setState(prevState => {
          return {
            ...prevState,
            disableBtn: false,
          };
        });
      });
  };

  const handleApproval = (userId, status) => {
    if (
      window.confirm(`Are you sure you want this application to be ${status}?`)
    ) {
      adminProcessProfClientApplication(userId, status)
        .then(res => {
          if (res.data.success) {
            createNotification("success");
          } else createNotification("error");

          triggerLoadUsers();
        })
        .catch(err => {
          createNotification("error");
          console.error(err);
        });
    }
  };

  const createNotification = (type, message) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the client.",
          "Client Update",
          5000
        );
        break;
      case "successNotif":
        NotificationManager.success(
          "You have successfully updated the client' notification settings.",
          "Client Update",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  const { validated, users, loadingUsers, selectedUser, disableBtn } = state;

  const selectOptionsCompletedApp = {
    0: "All",
    1: "Incomplete",
    2: "Completed",
  };

  const selectOptionsKYCApproved = {
    0: "All",
    1: "Non-approved",
    2: "Approved",
    3: "Pending",
  };

  const selectOptionsFXL = {
    0: "All",
    1: "Yes",
    2: "No",
  };

  const customFilterAgentCode = (filterVal, data) => {
    const search = filterVal.trim();

    if (search === "*") {
      return data.filter(user => user.role === "PARTNER");
    }
    if (search.length > 1) {
      return data.filter(user => user.agentCode.toString().includes(search));
    }
    return data;
  };

  const customFilterCompletedApp = (filterVal, data) => {
    const search = parseInt(filterVal);

    if (search > 0) {
      return data.filter(
        user =>
          (user.registered === true && search === 2) ||
          (user.registered === false && search === 1)
      );
    }
    return data;
  };

  const customFilterKYCApproved = (filterVal, data) => {
    let search = parseInt(filterVal);

    if (search > 0) {
      // 1: "Non-approved",
      // 2: "Approved",
      // 3: "Pending",
      if (search === 1)
        return data.filter(user => user.gcenApiKYCPass === false);
      if (search === 2)
        return data.filter(user => user.gcenApiKYCPass === true);
      if (search === 3) return data.filter(user => user.eKYCPending === true);
      return data;
    }
    return data;
  };

  // const customFilterFXL = (filterVal, data) => {
  //   let search = parseInt(filterVal);
  //
  //   if (search > 0) {
  //     return data.filter(
  //       user => user.fxlRegistered === selectOptionsFXL[search]
  //     );
  //   }
  //   return data;
  // };

  const columns = [
    {
      dataField: "userStatus",
      text: "STATUS",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "timestamp",
      text: "TIME OF REGISTRATION",
      sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "accountType",
      text: "APPLICATION TYPE",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "name",
      text: "FIRST NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "surname",
      text: "LAST NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "userCountry",
      text: "COUNTRY OF RESID.",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "agentCodeField",
      text: "AGENT CODE",
      sort: true,
      filter: textFilter({
        placeholder: "Search...",
        onFilter: customFilterAgentCode,
      }),
    },
    {
      dataField: "completedApplication",
      text: "COMPLETE APPLICATION",
      // sort: true,
      filter: selectFilter({
        options: selectOptionsCompletedApp,
        onFilter: customFilterCompletedApp,
      }),
    },
    {
      dataField: "eKYCApprovedIcon",
      text: "eKYC APPROVED",
      // sort: true,
      filter: selectFilter({
        options: selectOptionsKYCApproved,
        onFilter: customFilterKYCApproved,
      }),
    },
    // {
    //   dataField: "fxlRegistered",
    //   text: "FXL",
    //   sort: true,
    //   filter: selectFilter({
    //     options: selectOptionsFXL,
    //     onFilter: customFilterFXL,
    //   }),
    // },
    {
      dataField: "profile",
      text: "PROFILE",
    },
    {
      dataField: "approve",
      text: "",
    },
    {
      dataField: "reject",
      text: "",
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setState(prevState => {
        return {
          ...prevState,
          editingUser: true,
          selectedUser: row,
        };
      });

      setEmailNotifications({});

      adminGetEmailNotifications(row._id)
        .then(res => {
          setEmailNotifications(res.data[0]);
        })
        .catch(err => {
          createNotification("error");
          console.log(err);
        });
    },
  };

  let data = [];
  if (Object.keys(users).length > 0) {
    for (let i = 0; i < users.length; i++) {
      // Some data formatting before displaying
      let userStatusClass;
      let userStatusIcon;

      let statusText = users[i].status;
      switch (users[i].status) {
        case "new":
          userStatusClass = "statusFlagNewClient";
          userStatusIcon = IconNewClient;
          statusText = "New Client";
          break;
        case "pending":
          userStatusClass = "statusFlagPending";
          userStatusIcon = IconPending;
          break;
        case "rejected":
          userStatusClass = "statusFlagRejected";
          userStatusIcon = IconRejected;
          break;
        default:
          userStatusClass = "statusFlagApproved";
          userStatusIcon = IconApproved;
      }

      users[i].userStatus = (
        <span
          className={["statusFlag border border-white", userStatusClass].join(
            " "
          )}
        >
          <img src={userStatusIcon} alt="" /> {statusText}
        </span>
      );

      if (Number.isInteger(users[i].timestamp)) {
        users[i].timestamp = moment(users[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();

        users[i].agentCodeField =
          users[i].role === "PARTNER" ? (
            <>
              {users[i].agentCode}{" "}
              <FaCrown title="PARTNER" style={{ color: "orange" }} />
            </>
          ) : (
            users[i].agentCode
          );
      }

      users[i].userCountry = countryList[users[i].country];

      users[i].eKYCApprovedIcon =
        users[i].gcenApiKYCPass === true ? (
          <img src={IconApproved} alt="" />
        ) : users[i].eKYCFlagged ? (
          <span className="text-danger">FLAGGED</span>
        ) : (
          <img src={IconRejected} alt="" />
        );
      // Overrides KYC approved icon in case user has uploaded all documents and pending approval
      if (users[i].eKYCPending) {
        users[i].eKYCApprovedIcon = <img src={IconPendingYellow} alt="" />;
      }

      users[i].completedApplication =
        users[i].registered === true ? (
          <img src={IconApproved} alt="" />
        ) : (
          <img src={IconRejected} alt="" />
        );

      // users[i].fxlRegistered = state.fxlUsers[users[i].email] ? "Yes" : "No";

      users[i].profile = (
        <Button
          type="button"
          variant="link"
          className="blueButton text-white w-auto"
          as={Link}
          to={`/user/profile/${users[i]._id}`}
        >
          PROFILE
        </Button>
      );

      if (users[i].professionalClientStatus !== "approved")
        users[i].approve = (
          <Button
            type="button"
            variant="link"
            className="greenButton font-weight-bold text-white w-auto"
            onClick={() => handleApproval(users[i]._id, "approved")}
          >
            APPROVE
          </Button>
        );
      if (users[i].professionalClientStatus !== "rejected")
        users[i].reject = (
          <Button
            type="button"
            variant="link"
            className="redButton text-white w-auto"
            onClick={() => handleApproval(users[i]._id, "rejected")}
          >
            REJECT
          </Button>
        );

      data.push(users[i]);
    }
  }

  const rowStyle = (row, rowIndex) => {
    let style = {
      backgroundColor: "#ffffe0",
    };

    if (row.professionalClientStatus === "approved") {
      style.backgroundColor = "var(--color-green2)";
    }
    if (row.professionalClientStatus === "rejected") {
      style.backgroundColor = "var(--color-red2)";
    }

    if (row.status === "approved" && !row.firstAccountCreated) {
      style.color = "#f00";
    }

    return style;
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="pt-3 px-3">
            Professional Clients -{" "}
            <span className="text-capitalize">{props.match.params.status}</span>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "pending" ? "disabled" : "",
              ]}
              as={Link}
              to={"/professional-clients/pending"}
            >
              Pending
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "approved" ? "disabled" : "",
              ]}
              as={Link}
              to={"/professional-clients/approved"}
            >
              Approved
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "rejected" ? "disabled" : "",
              ]}
              as={Link}
              to={"/professional-clients/rejected"}
            >
              Rejected
            </Button>
            {loadingUsers ? (
              <Spinner animation="border" size="sm" className="float-right" />
            ) : null}
          </h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            filter={filterFactory()}
            rowEvents={rowEvents}
            rowStyle={rowStyle}
            headerClasses="tableHeaderClass"
          />

          {selectedUser ? (
            <Modal show={state.editingUser} onHide={handleCloseModal} size="lg">
              <Modal.Header className="border-0" closeButton>
                <Modal.Title>Edit User</Modal.Title>
              </Modal.Header>

              <Tabs
                defaultActiveKey="profile"
                transition={false}
                id="user-settings-tabs"
              >
                <Tab eventKey="profile" title="Profile" className="py-4">
                  <Form validated={validated} onSubmit={e => handleSubmit(e)}>
                    <Modal.Body>
                      <Form.Row>
                        {userRoles[props.auth.user.role] >=
                        userRoles["ADMIN"] ? (
                          <Form.Group as={Col} md="6" xs="12">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                              as="select"
                              name="role"
                              required
                              onChange={e => handleChangeProfile(e, "")}
                              defaultValue={selectedUser.role}
                            >
                              <option value="">Choose user role</option>
                              {getUserRolesSelectListOptions(
                                userRoles[props.auth.user.role]
                              )}
                            </Form.Control>
                          </Form.Group>
                        ) : null}

                        {userRoles[props.auth.user.role] > userRoles["ADMIN"] ||
                        (userRoles[props.auth.user.role] ===
                          userRoles["ADMIN"] &&
                          selectedUser.role !== "ADMIN") ? (
                          <Form.Group as={Col} md="6" xs="12">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                              as="select"
                              name="status"
                              required
                              onChange={e => handleChangeProfile(e, "")}
                              defaultValue={selectedUser.status}
                            >
                              <option value="">Choose user status</option>
                              {getUserStatusSelectListOptions()}
                            </Form.Control>
                          </Form.Group>
                        ) : null}
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            disabled
                            defaultValue={selectedUser.email}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.email}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            onChange={e => handleChangeProfile(e, "")}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.password}
                            {state.errors.passwordincorrect}
                          </Form.Text>
                        </Form.Group>

                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Confirm password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password2"
                            placeholder="Confirm password"
                            onChange={e => handleChangeProfile(e, "")}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.password2}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="2" xs="6">
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            as="select"
                            name="title"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.title}
                          >
                            <option value="">Select title</option>
                            <option>Mr.</option>
                            <option>Mrs.</option>
                            <option>Dr.</option>
                          </Form.Control>
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.title}
                          </Form.Text>
                        </Form.Group>

                        <Form.Group as={Col} md="4" xs="6">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.name}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.name}
                          </Form.Text>
                        </Form.Group>

                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Surname</Form.Label>
                          <Form.Control
                            type="text"
                            name="surname"
                            placeholder="Enter your surname"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.surname}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.surname}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Col md="6" xs="12">
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label>Birth day</Form.Label>
                              <Select
                                name="birthDay"
                                onChange={e =>
                                  handleChangeProfile(e, "birthDay")
                                }
                                options={getBirthDaysReactSelect()}
                                value={{
                                  label: selectedUser.birthDay || "",
                                  value: selectedUser.birthDay || "",
                                }}
                              />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Birth month</Form.Label>
                              <Select
                                name="birthMonth"
                                onChange={e =>
                                  handleChangeProfile(e, "birthMonth")
                                }
                                options={getBirthMonthsReactSelect()}
                                value={{
                                  label: selectedUser.birthMonth || "",
                                  value: selectedUser.birthMonth || "",
                                }}
                              />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Birth year</Form.Label>
                              <Select
                                name="birthYear"
                                onChange={e =>
                                  handleChangeProfile(e, "birthYear")
                                }
                                options={getBirthYearsReactSelect()}
                                value={{
                                  label: selectedUser.birthYear || "",
                                  value: selectedUser.birthYear || "",
                                }}
                              />
                            </Form.Group>
                          </Form.Row>
                        </Col>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Mobile phone</Form.Label>
                          <Form.Control
                            type="text"
                            name="mobilePhone"
                            placeholder="Enter your phone number"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.mobilePhone}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.mobilePhone}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Street number</Form.Label>
                          <Form.Control
                            type="text"
                            name="addressNo"
                            placeholder="Enter your street number"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.addressNo}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.address}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Street address</Form.Label>
                          <Form.Control
                            type="text"
                            name="address"
                            placeholder="Enter your street address"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.address}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.address}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            name="city"
                            placeholder="Enter your city"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.city}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.city}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Postcode</Form.Label>
                          <Form.Control
                            type="text"
                            name="postcode"
                            placeholder="Enter your postcode"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.postcode}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.postcode}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>County</Form.Label>
                          <Form.Control
                            type="text"
                            name="county"
                            placeholder="Enter your county"
                            onChange={e => handleChangeProfile(e, "county")}
                            defaultValue={selectedUser.county}
                          />
                        </Form.Group>

                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Country</Form.Label>
                          <Select
                            name="country"
                            onChange={e => handleChangeProfile(e, "country")}
                            options={countryListReactSelect()}
                            value={{
                              label: countryList[selectedUser.country],
                              value: selectedUser.country,
                            }}
                          />
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Nationality</Form.Label>
                          <Select
                            name="nationality"
                            onChange={e =>
                              handleChangeProfile(e, "nationality")
                            }
                            options={countryListReactSelect()}
                            value={{
                              label: selectedUser.nationality
                                ? countryList[selectedUser.nationality]
                                : "",
                              value: selectedUser.nationality
                                ? selectedUser.nationality
                                : "",
                            }}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.nationality}
                          </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Agent code</Form.Label>
                          <Form.Control
                            type="text"
                            name="agentCode"
                            placeholder="Enter Agent code"
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.agentCode}
                          />
                          <Form.Text style={{ color: "red" }}>
                            {state.errors.agentCode}
                          </Form.Text>
                        </Form.Group>
                      </Form.Row>

                      <Form.Group controlId="emailVerified">
                        <Form.Check
                          type="checkbox"
                          name="emailVerified"
                          label="Email verified"
                          checked={selectedUser.emailVerified}
                          onChange={e => handleChangeProfile(e, "")}
                        />
                      </Form.Group>

                      <Form.Text style={{ color: "red" }}>
                        {state.errors.errors || state.errors.server}
                      </Form.Text>
                    </Modal.Body>
                    <Modal.Footer className="border-0 justify-content-between">
                      <Button
                        variant="secondary"
                        className="greyButton"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </Button>

                      {/*<Button*/}
                      {/*  style={{*/}
                      {/*    backgroundColor: "var(--color-light-blue2)",*/}
                      {/*  }}*/}
                      {/*  as={Link}*/}
                      {/*  to={`/user/documents/${selectedUser._id}`}*/}
                      {/*>*/}
                      {/*  Documents*/}
                      {/*</Button>*/}

                      <Button
                        variant="primary"
                        type="submit"
                        className="darkBlueButton"
                        style={{ fontWeight: "normal" }}
                        disabled={disableBtn}
                      >
                        {disableBtn ? (
                          <>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Submit
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Tab>

                {selectedUser.role === "PARTNER" ? (
                  <Tab
                    eventKey="email-settings"
                    title="Email settings"
                    className="py-4"
                  >
                    <Form onSubmit={e => handleSubmitEmailNotifications(e)}>
                      <Modal.Body>
                        <Form.Group as={Row}>
                          <Form.Label
                            className={[classes.label, "pt-2"].join(" ")}
                            as={Col}
                            xs="12"
                            md="6"
                          >
                            User Registered
                          </Form.Label>
                          <Col xs="12" md="6">
                            <Form.Control
                              type="text"
                              name="partnerUserRegistered"
                              id="partnerUserRegistered"
                              defaultValue={
                                emailNotifications &&
                                emailNotifications.partnerUserRegistered
                                  ? emailNotifications.partnerUserRegistered
                                  : ""
                              }
                              required
                            />
                          </Col>

                          <Form.Text style={{ color: "red" }}>
                            {state.errors.partnerUserRegistered}
                          </Form.Text>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label
                            className={[classes.label, "pt-2"].join(" ")}
                            as={Col}
                            xs="12"
                            md="6"
                          >
                            User Approved
                          </Form.Label>
                          <Col xs="12" md="6">
                            <Form.Control
                              type="text"
                              name="partnerUserApproved"
                              id="partnerUserApproved"
                              defaultValue={
                                emailNotifications &&
                                emailNotifications.partnerUserApproved
                                  ? emailNotifications.partnerUserApproved
                                  : ""
                              }
                              required
                            />
                          </Col>

                          <Form.Text style={{ color: "red" }}>
                            {state.errors.partnerUserApproved}
                          </Form.Text>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label
                            className={[classes.label, "pt-2"].join(" ")}
                            as={Col}
                            xs="12"
                            md="6"
                          >
                            User KYC Approved
                          </Form.Label>
                          <Col xs="12" md="6">
                            <Form.Control
                              type="text"
                              name="partnerUserKYCApproved"
                              id="partnerUserKYCApproved"
                              defaultValue={
                                emailNotifications &&
                                emailNotifications.partnerUserKYCApproved
                                  ? emailNotifications.partnerUserKYCApproved
                                  : ""
                              }
                              required
                            />
                          </Col>

                          <Form.Text style={{ color: "red" }}>
                            {state.errors.partnerUserKYCApproved}
                          </Form.Text>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label
                            className={[classes.label, "pt-2"].join(" ")}
                            as={Col}
                            xs="12"
                            md="6"
                          >
                            User Deposited
                          </Form.Label>
                          <Col xs="12" md="6">
                            <Form.Control
                              type="text"
                              name="partnerUserDeposited"
                              id="partnerUserDeposited"
                              defaultValue={
                                emailNotifications &&
                                emailNotifications.partnerUserDeposited
                                  ? emailNotifications.partnerUserDeposited
                                  : ""
                              }
                              required
                            />
                          </Col>

                          <Form.Text style={{ color: "red" }}>
                            {state.errors.partnerUserDeposited}
                          </Form.Text>
                        </Form.Group>

                        {/*<Form.Group as={Row}>*/}
                        {/*  <Form.Label*/}
                        {/*    className={[classes.label, "pt-2"].join(" ")}*/}
                        {/*    as={Col}*/}
                        {/*    xs="12"*/}
                        {/*    md="6"*/}
                        {/*  >*/}
                        {/*    User Requested Wire Transfer*/}
                        {/*  </Form.Label>*/}
                        {/*  <Col xs="12" md="6">*/}
                        {/*    <Form.Control*/}
                        {/*      type="text"*/}
                        {/*      name="partnerUserRequestedWireTransfer"*/}
                        {/*      id="partnerUserRequestedWireTransfer"*/}
                        {/*      defaultValue={*/}
                        {/*        emailNotifications &&*/}
                        {/*        emailNotifications.partnerUserRequestedWireTransfer*/}
                        {/*          ? emailNotifications.partnerUserRequestedWireTransfer*/}
                        {/*          : ""*/}
                        {/*      }*/}
                        {/*      required*/}
                        {/*    />*/}
                        {/*  </Col>*/}

                        {/*  <Form.Text style={{ color: "red" }}>*/}
                        {/*    {state.errors.partnerUserRequestedWireTransfer}*/}
                        {/*  </Form.Text>*/}
                        {/*</Form.Group>*/}

                        <Form.Group as={Row}>
                          <Form.Label
                            className={[classes.label, "pt-2"].join(" ")}
                            as={Col}
                            xs="12"
                            md="6"
                          >
                            User Withdrawal Request
                          </Form.Label>
                          <Col xs="12" md="6">
                            <Form.Control
                              type="text"
                              name="partnerUserWithdrawalRequest"
                              id="partnerUserWithdrawalRequest"
                              defaultValue={
                                emailNotifications &&
                                emailNotifications.partnerUserWithdrawalRequest
                                  ? emailNotifications.partnerUserWithdrawalRequest
                                  : ""
                              }
                              required
                            />
                          </Col>

                          <Form.Text style={{ color: "red" }}>
                            {state.errors.partnerUserWithdrawalRequest}
                          </Form.Text>
                        </Form.Group>

                        <Form.Text className="text-left pb-4">
                          {state.errors.server ? (
                            <FiAlertTriangle className="warningIcon" />
                          ) : null}
                          {state.errors.server}
                          {state.errors.password}
                        </Form.Text>

                        <Button
                          type="submit"
                          className="darkBlueButton"
                          style={{ fontWeight: "normal" }}
                          onClick={handleSubmitEmailNotifications}
                          disabled={disableBtn}
                        >
                          {disableBtn ? (
                            <>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              UPDATE
                            </>
                          ) : (
                            "UPDATE"
                          )}
                        </Button>
                      </Modal.Body>
                    </Form>
                  </Tab>
                ) : null}
              </Tabs>
            </Modal>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

ProfessionalClients.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfessionalClients);
