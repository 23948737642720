import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userRoles } from "../../../config/constants";
import {
  Form,
  Button,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";

import {
  getGroups,
  adminAddGroup,
  adminEditGroup,
  adminDeleteGroup,
  clearSuccessErrorProps,
} from "../../../redux/actions/adminActions";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";

const ManageGroups = props => {
  const [state, setState] = useState({
    groups: [],
    addGroupErrors: "",
    showAddGroupModal: false,
    showEditGroupModal: false,
    disableAddGroupBtn: false,
    selectedGroup: {},
    loading: true,
  });

  useEffect(() => {
    triggerGetGroups();
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      clearSuccessErrorProps();
      triggerGetGroups();
    }

    if (
      Object.keys(props.errors).length > 0 &&
      props.errors.group !== undefined
    ) {
      setState(prevState => {
        return {
          ...prevState,
          addGroupErrors: props.errors.group,
          disableAddGroupBtn: false,
        };
      });
      clearSuccessErrorProps();
    }
  }, [props]);

  const triggerGetGroups = () => {
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    getGroups()
      .then(res => {
        setState(prevState => {
          return {
            ...prevState,
            groups: res.data.groups,
            loading: false,
          };
        });
      })
      .catch(err => console.log(err));
  };

  const handleAddGroup = () => {
    const groupData = {
      groupId: document.getElementById("newGroupId").value,
      groupName: document.getElementById("newGroupName").value,
      groupStatus: document.getElementById("newGroupStatus").value,
    };

    if (
      groupData.groupId !== "" &&
      groupData.groupName !== "" &&
      groupData.groupStatus !== ""
    ) {
      setState(prevState => {
        return {
          ...prevState,
          disableAddGroupBtn: true,
        };
      });

      props.adminAddGroup(groupData);
    }
  };

  const handleEditGroup = () => {
    const groupData = {
      groupId: document.getElementById("editGroupId").value,
      groupName: document.getElementById("editGroupName").value,
      groupStatus: document.getElementById("editGroupStatus").value,
    };

    if (
      groupData.groupId !== "" &&
      groupData.groupName !== "" &&
      groupData.groupStatus !== ""
    ) {
      setState(prevState => {
        return {
          ...prevState,
          disableAddGroupBtn: true,
        };
      });

      props.adminEditGroup(groupData);
    }
  };

  const handleDeleteGroup = group => {
    if (
      window.confirm("Are you sure you want to DELETE group " + group + "?")
    ) {
      setState(prevState => {
        return {
          ...prevState,
          disableAddGroupBtn: true,
        };
      });

      props.adminDeleteGroup(group);
    }
  };

  const handleModalClose = target => {
    setState(prevState => {
      return { ...prevState, [target]: false };
    });
  };

  const handleAddGroupModalShow = () => {
    setState(prevState => {
      return { ...prevState, showAddGroupModal: true };
    });
  };

  const columns = [
    {
      dataField: "groupId",
      text: "Group ID",
      sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "groupName",
      text: "Group name",
      sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "groupStatus",
      text: "Status",
      sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "timestamp",
      text: "Added",
      sort: true,
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (userRoles[props.auth.user.role] < userRoles["ADMIN"]) {
        return false;
      }

      setState(prevState => {
        return {
          ...prevState,
          showEditGroupModal: true,
          selectedGroup: row,
        };
      });
    },
  };

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  let { groups } = state;
  let data = [];
  if (!!groups) {
    Object.entries(groups).forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(groups[keyIndex].timestamp)) {
        groups[keyIndex].timestamp = moment(groups[keyIndex].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      data.push(groups[keyIndex]);
    });
  }

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (row.Copy === "D") {
      style = { backgroundColor: "#FFAAAA" };
    }

    return style;
  };

  return (
    <>
      {userRoles[props.auth.user.role] >= userRoles["ADMIN"] ? (
        <Container className="mw1200" fluid>
          <Row className="justify-content-lg-center">
            <Col>
              <Button
                type="button"
                variant="secondary"
                className="float-right"
                onClick={handleAddGroupModalShow}
              >
                Add group
              </Button>
            </Col>
          </Row>
        </Container>
      ) : null}

      <Container className="mw1200" fluid>
        <Row className="justify-content-lg-center">
          <Col>
            <h1>
              Manage groups
              {state.loading ? (
                <Spinner animation="border" size="sm" className="float-right" />
              ) : null}
            </h1>
          </Col>
        </Row>

        <Row className="justify-content-lg-center">
          <Col>
            Total: <strong>{state.groups.length}</strong> groups
          </Col>
        </Row>

        <Row className="justify-content-lg-center">
          <Col>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              // filter={filterFactory()}
              rowStyle={rowStyle}
              rowEvents={rowEvents}
            />
          </Col>
        </Row>
      </Container>

      <Modal
        show={state.showAddGroupModal}
        onHide={() => handleModalClose("showAddGroupModal")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Group</Modal.Title>
        </Modal.Header>
        <Form onSubmit={e => e.preventDefault()}>
          <Modal.Body>
            <Form.Group controlId="newGroupId">
              <Form.Label>Group ID</Form.Label>
              <Form.Control type="text" required />
            </Form.Group>

            <Form.Group controlId="newGroupName">
              <Form.Label>Group name</Form.Label>
              <Form.Control type="text" required />
            </Form.Group>

            <Form.Group controlId="newGroupStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" required>
                <option value="">Select status</option>
                <option value="active">Active</option>
                <option value="disabled">Disabled</option>
              </Form.Control>
            </Form.Group>

            <Form.Text style={{ color: "red" }}>
              {props.errors.server}
              {state.addGroupErrors}
            </Form.Text>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-between">
            <Button
              type="submit"
              style={{
                backgroundColor: "var(--color-light-blue2)",
              }}
              onClick={handleAddGroup}
              disabled={state.disableAddGroupBtn}
            >
              {state.disableAddGroupBtn ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Add
                </>
              ) : (
                "Add"
              )}
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleModalClose("showAddGroupModal")}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={state.showEditGroupModal}
        onHide={() => handleModalClose("showEditGroupModal")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Form onSubmit={e => e.preventDefault()}>
          <Modal.Body>
            <Form.Group controlId="editGroupId">
              <Form.Label>Group ID</Form.Label>
              <Form.Control
                type="text"
                defaultValue={state.selectedGroup.groupId}
                required
              />
            </Form.Group>

            <Form.Group controlId="editGroupName">
              <Form.Label>Group name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={state.selectedGroup.groupName}
                required
              />
            </Form.Group>

            <Form.Group controlId="editGroupStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                defaultValue={state.selectedGroup.groupStatus}
                required
              >
                <option value="">Select status</option>
                <option value="active">Active</option>
                <option value="disabled">Disabled</option>
              </Form.Control>
            </Form.Group>

            <Form.Text style={{ color: "red" }}>
              {props.errors.server}
              {state.addGroupErrors}
            </Form.Text>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-between">
            <Button
              type="submit"
              style={{
                backgroundColor: "var(--color-light-blue2)",
              }}
              onClick={handleEditGroup}
              disabled={state.disableAddGroupBtn}
            >
              {state.disableAddGroupBtn ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Save
                </>
              ) : (
                "Save"
              )}
            </Button>
            <Button
              type="submit"
              variant="danger"
              onClick={() => handleDeleteGroup(state.selectedGroup.groupId)}
              disabled={state.disableAddGroupBtn}
            >
              {state.disableAddGroupBtn ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Delete
                </>
              ) : (
                "Delete"
              )}
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleModalClose("showEditGroupModal")}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

ManageGroups.propTypes = {
  auth: PropTypes.object.isRequired,
  adminAddGroup: PropTypes.func.isRequired,
  adminEditGroup: PropTypes.func.isRequired,
  adminDeleteGroup: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  adminAddGroup,
  adminEditGroup,
  adminDeleteGroup,
})(ManageGroups);
