import React, { useState, useEffect } from "react";
import { filterBaseCurrency } from "../../../config/constants";
import {
  getAdmins,
  adminGetFundingRequests,
  adminUpdateDeposit,
  adminProcessDeposit,
} from "../../../redux/actions/adminActions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import {
  capitalize,
  getRequestStatusSelectListOptions,
} from "../../../utils/utils";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

const DepositRequests = props => {
  const [state, setState] = useState({
    showDepositModal: false,
    disableEditDepositBtn: false,
    selectedDeposit: {},
    loading: true,
  });
  const [admins, setAdmins] = useState({});
  const [clients, setClients] = useState({});
  const [deposits, setDeposits] = useState(null);

  useEffect(() => {
    getAdmins()
      .then(res => {
        setAdmins(res.data.admins);
      })
      .catch(err => {
        createNotification("error");
        console.error(err);
      });
  }, []);

  useEffect(() => {
    triggerAdminGetFundingRequests();
  }, [props.match.params.status]);

  const handleUpdateDeposit = e => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "dontEmailUser" || name === "modifyDealAmount") {
      value = e.target.checked;
    }

    const selectedDeposit = state.selectedDeposit;
    selectedDeposit[name] = value;
    setState(prevState => {
      return { ...prevState, selectedDeposit };
    });
  };

  const triggerAdminGetFundingRequests = () => {
    setDeposits([]);
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    adminGetFundingRequests("all", "deposit", props.match.params.status)
      .then(res => {
        setClients(res.data.clients);
        setDeposits(res.data.transfers);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => {
        createNotification("error");
        console.error(err);
      });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        disableEditDepositBtn: false,
        showDepositModal: false,
      };
    });
  };

  const handleApproveDeposit = depositId => {
    if (window.confirm("Are you sure you want to approve this deposit?")) {
      adminProcessDeposit(depositId)
        .then(res => {
          if (res.data.success) {
            handleCloseModal();
            triggerAdminGetFundingRequests();
            createNotification("success");
          } else createNotification("error");
        })
        .catch(err => {
          createNotification("error");
          console.error(err);
        });
    }
  };

  const handleRejectDeposit = deposit => {
    deposit.status = "rejected";
    setState(prevState => {
      return { ...prevState, showDepositModal: true, selectedDeposit: deposit };
    });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const selectedDeposit = state.selectedDeposit;

    if (
      selectedDeposit.status === "rejected" &&
      (!selectedDeposit.comment || selectedDeposit.comment.trim() === "")
    ) {
      createNotification("error", "You cannot reject deposit without a reason");
      return false;
    }

    setState(prevState => {
      return { ...prevState, disableEditDepositBtn: true };
    });

    if (form.checkValidity()) {
      let updateDeposit = {
        _id: selectedDeposit._id,
        status: selectedDeposit.status,
        comment: selectedDeposit.comment,
        modifyDealAmount: selectedDeposit.modifyDealAmount,
        depositAmountSent: selectedDeposit.depositAmountSent,
      };

      adminUpdateDeposit(updateDeposit)
        .then(res => {
          if (res.data.success) {
            handleCloseModal();
            triggerAdminGetFundingRequests();
            createNotification("success");
          } else createNotification("error");
        })
        .catch(err => {
          createNotification("error");
          console.error(err);
        });
    }
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the deposit request.",
          "Deposit Update",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  const customFilterCurrency = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.depositCurrency === filterVal);
    }
    return data;
  };

  let columns = [
    {
      dataField: "gateway",
      text: "GATEWAY",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "timestamp",
      text: "TIME OF REQUEST",
      sort: true,
    },
    {
      dataField: "depositType",
      text: "PAYMENT METHOD",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "firstName",
      text: "FIRST NAME",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "lastName",
      text: "LAST NAME",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "DlCode",
      text: "TRANSACTION ID",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "accountNumber",
      text: "TRADING ACCOUNT NUMBER",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "depositAmountSent",
      text: "AMOUNT",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "depositCurrency",
      text: "CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    // {
    //   dataField: "fee",
    //   text: "FEE",
    // },
  ];
  if (props.match.params.status === "incomplete") {
    columns = [
      ...columns,
      {
        dataField: "reject",
        text: "",
      },
      {
        dataField: "approve",
        text: "",
      },
    ];
  }
  if (
    props.match.params.status !== "pending" &&
    props.match.params.status !== "incomplete"
  ) {
    columns = [
      ...columns,
      {
        dataField: "timeApproved",
        text: "PROCESSED TIME",
      },
      {
        dataField: "adminId",
        text: "PROCESSED BY",
      },
    ];
  }

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setState(prevState => {
        return { ...prevState, showDepositModal: true, selectedDeposit: row };
      });
    },
  };

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (
      (row.status === "approved" && !row.MTStatus) ||
      (row.status === "approved" &&
        row.MTStatus &&
        row.MTStatus !== "SUCCESS") ||
      (row.MTStatus && row.MTStatus !== "SUCCESS")
    ) {
      style = { backgroundColor: "#ff0000", color: "#fff" };
    }

    if (
      !row.status ||
      row.status === "declined" ||
      row.status === "rejected" ||
      row.status === "error"
    ) {
      style = { backgroundColor: "#ffb5b5" };
    }

    return style;
  };

  let data = [];
  if (!!deposits) {
    deposits.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (deposits[keyIndex].depositType.toLowerCase() === "wire") {
        deposits[keyIndex].gateway = "Bank Wire";
      } else {
        deposits[keyIndex].gateway = `${
          deposits[keyIndex].paymentGatewayService
        } - ${
          deposits[keyIndex].depositPaymentName ||
          capitalize(deposits[keyIndex].depositType.replace("_", " "))
        }`;
      }

      deposits[keyIndex].depositType = capitalize(
        deposits[keyIndex].depositType.replace("_", " ")
      );

      deposits[keyIndex].gateway = deposits[keyIndex].gateway.replace(
        "IDeal",
        "iDeal"
      );
      deposits[keyIndex].gateway = deposits[keyIndex].gateway.replace(
        "Ideal",
        "iDeal"
      );
      deposits[keyIndex].depositType = deposits[keyIndex].depositType.replace(
        "IDeal",
        "iDeal"
      );
      deposits[keyIndex].depositType = deposits[keyIndex].depositType.replace(
        "Ideal",
        "iDeal"
      );

      if (Number.isInteger(deposits[keyIndex].timestamp)) {
        deposits[keyIndex].timestamp = moment(
          deposits[keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (clients[deposits[keyIndex].userId]) {
        deposits[keyIndex].firstName = clients[deposits[keyIndex].userId].name;
        deposits[keyIndex].lastName =
          clients[deposits[keyIndex].userId].surname;
      }

      deposits[keyIndex].depositAmountSent = parseFloat(
        deposits[keyIndex].depositAmountSent
      ).toFixed(2);

      deposits[keyIndex].fee = "N/A";

      if (deposits[keyIndex].adminId && admins[deposits[keyIndex].adminId])
        deposits[keyIndex].adminId = admins[deposits[keyIndex].adminId];
      if (!deposits[keyIndex].adminId || deposits[keyIndex].adminId === "0")
        deposits[keyIndex].adminId = "AUTOMATION";

      if (props.match.params.status === "incomplete") {
        deposits[keyIndex].reject = (
          <Button
            type="button"
            variant="link"
            className="redButton text-white"
            onClick={() => handleRejectDeposit(deposits[keyIndex])}
          >
            REJECT
          </Button>
        );
        deposits[keyIndex].approve = (
          <Button
            type="button"
            variant="link"
            className="blueButton text-white"
            onClick={() => handleApproveDeposit(deposits[keyIndex]._id)}
          >
            APPROVE
          </Button>
        );
      } else {
        if (Number.isInteger(deposits[keyIndex].timeApproved)) {
          deposits[keyIndex].timeApproved = moment(
            deposits[keyIndex].timeApproved * 1000
          )
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
        }
      }

      data.push(deposits[keyIndex]);
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="px-3">
            Deposits requests -{" "}
            <span className="text-capitalize">{props.match.params.status}</span>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "historical" ? "disabled" : "",
              ]}
              as={Link}
              to={"/deposit-requests/historical"}
            >
              Historical
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "pending" ? "disabled" : "",
              ]}
              as={Link}
              to={"/deposit-requests/pending"}
            >
              Pending
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "incomplete" ? "disabled" : "",
              ]}
              as={Link}
              to={"/deposit-requests/incomplete"}
            >
              Incomplete
            </Button>
            {state.loading ? (
              <Spinner animation="border" size="sm" className="float-right" />
            ) : null}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            filter={filterFactory()}
            rowEvents={rowEvents}
            rowStyle={rowStyle}
            headerClasses="tableHeaderClass"
          />

          <Modal
            show={state.showDepositModal}
            onHide={handleCloseModal}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update deposit</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={e => handleSubmit(e)}>
                <Form.Group>
                  <Form.Label>Acc. number</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    placeholder="Enter acc. number"
                    onChange={e => handleUpdateDeposit(e)}
                    defaultValue={state.selectedDeposit.accountNumber}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Type: {state.selectedDeposit.depositType}
                  </Form.Label>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedDeposit.depositCurrency}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="w-100">
                    Deal amount
                    <Form.Group
                      controlId="modifyDealAmount"
                      className="d-inline-block float-right m-0"
                    >
                      <Form.Check
                        type="checkbox"
                        name="modifyDealAmount"
                        label="Modify deal amount"
                        checked={state.selectedDeposit.modifyDealAmount}
                        onChange={e => handleUpdateDeposit(e)}
                      />
                    </Form.Group>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    name="depositAmountSent"
                    disabled={!state.selectedDeposit.modifyDealAmount}
                    defaultValue={state.selectedDeposit.depositAmountSent}
                    onChange={e => handleUpdateDeposit(e)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="comment"
                    onChange={e => handleUpdateDeposit(e)}
                    defaultValue={state.selectedDeposit.comment}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    required
                    onChange={e => handleUpdateDeposit(e)}
                    defaultValue={state.selectedDeposit.status}
                  >
                    <option value="">Choose deposit status</option>
                    {getRequestStatusSelectListOptions()}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="dontEmailUser">
                  <Form.Check
                    type="checkbox"
                    name="dontEmailUser"
                    label="Do not send email to user"
                    checked={state.selectedDeposit.dontEmailUser}
                    onChange={e => handleUpdateDeposit(e)}
                  />
                </Form.Group>

                <Form.Row className="justify-content-between">
                  <Button
                    variant="secondary"
                    className="greyButton"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    className="darkBlueButton"
                    style={{ fontWeight: "normal" }}
                    disabled={state.disableEditDepositBtn}
                  >
                    {state.disableEditDepositBtn ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Update
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Form.Row>
              </Form>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default DepositRequests;
