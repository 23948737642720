import React, { useState, useEffect } from "react";
import {
  adminGetAccounts,
  adminUpdateAccount,
} from "../../../redux/actions/adminActions";
import {
  filterAccountPlatform,
  filterAccountType,
  filterBaseCurrency,
} from "../../../config/constants";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import moment from "moment";
import { getAccountStatusSelectListOptions } from "../../../utils/utils";
import { NotificationManager } from "react-notifications";

const TradingAccounts = props => {
  const [state, setState] = useState({
    editAccountErrors: "",
    showAccountModal: false,
    disableEditAccountBtn: false,
    selectedAccount: {},
    loading: true,
  });
  const [accountsLive, setLiveAccounts] = useState(null);
  const [usersLive, setLiveUsers] = useState(null);
  const [accountsDemo, setDemoAccounts] = useState(null);
  const [usersDemo, setDemoUsers] = useState(null);

  useEffect(() => {
    triggerAdminGetAccounts();
  }, []);

  const triggerAdminGetAccounts = () => {
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    adminGetAccounts("all", "live", "approved")
      .then(res => {
        let users = {};
        res.data.users.map(user => (users[user._id] = user));
        setLiveUsers(users);

        setLiveAccounts(res.data.accounts);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.error(err));

    adminGetAccounts("all", "demo", "approved")
      .then(res => {
        let users = {};
        res.data.users.map(user => (users[user._id] = user));
        setDemoUsers(users);

        setDemoAccounts(res.data.accounts);
      })
      .catch(err => console.error(err));
  };

  const handleUpdateAccount = e => {
    let name = e.target.name;
    let value = e.target.value;

    const selectedAccount = state.selectedAccount;
    selectedAccount[name] = value;
    setState(prevState => {
      return { ...prevState, selectedAccount };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showAccountModal: false,
        disableEditAccountBtn: false,
      };
    });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const selectedAccount = state.selectedAccount;

    if (
      selectedAccount.status === "rejected" &&
      (!selectedAccount.comment || selectedAccount.comment.trim() === "")
    ) {
      createNotification("error", "You cannot reject account without a reason");
      return false;
    }

    setState(prevState => {
      return { ...prevState, disableEditAccountBtn: true };
    });

    if (form.checkValidity()) {
      adminUpdateAccount(selectedAccount)
        .then(res => {
          if (res.data.success) {
            createNotification("success");
            handleCloseModal();
            triggerAdminGetAccounts();
          } else createNotification("error");
        })
        .catch(err => {
          createNotification("error");
          console.error(err);
        });
    }
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the account.",
          "Account Update",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  const customFilterCurrency = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.baseCurrency === filterVal);
    }
    return data;
  };

  const customFilterAccountPlatform = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.platform === filterVal);
    }
    return data;
  };

  const customFilterAccountType = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.type === filterVal);
    }
    return data;
  };

  const columnsLive = [
    {
      dataField: "fullName",
      text: "FULL NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "type",
      text: "ACC. TYPE",
      sort: true,
      filter: selectFilter({
        options: filterAccountType,
        onFilter: customFilterAccountType,
      }),
    },
    {
      dataField: "number",
      text: "ACC. NUMBER",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "platform",
      text: "PLATFORM",
      sort: true,
      filter: selectFilter({
        options: filterAccountPlatform,
        onFilter: customFilterAccountPlatform,
      }),
    },
    {
      dataField: "balance",
      text: "BALANCE",
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "baseCurrency",
      text: "BASE CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    {
      dataField: "timeCreated",
      text: "CREATED",
      sort: true,
    },
    {
      dataField: "timeApproved",
      text: "APPROVED",
      sort: true,
    },
  ];

  const columnsDemo = [
    {
      dataField: "fullName",
      text: "FULL NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "type",
      text: "ACC. TYPE",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "number",
      text: "ACC. NUMBER",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "platform",
      text: "PLATFORM",
      sort: true,
      filter: selectFilter({
        options: filterAccountPlatform,
        onFilter: customFilterAccountPlatform,
      }),
    },
    {
      dataField: "balance",
      text: "BALANCE",
      sort: true,
      classes: "text-right",
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
    {
      dataField: "baseCurrency",
      text: "BASE CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    {
      dataField: "timeCreated",
      text: "CREATED",
      sort: true,
    },
    {
      dataField: "timeApproved",
      text: "APPROVED",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timeApproved",
      order: "asc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setState(prevState => {
        return { ...prevState, showAccountModal: true, selectedAccount: row };
      });
    },
  };

  let dataLive = [];
  if (!!accountsLive) {
    accountsLive.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(accountsLive[keyIndex].timeCreated)) {
        accountsLive[keyIndex].timeCreated = moment(
          accountsLive[keyIndex].timeCreated * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (Number.isInteger(accountsLive[keyIndex].timeApproved)) {
        accountsLive[keyIndex].timeApproved = moment(
          accountsLive[keyIndex].timeApproved * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      accountsLive[keyIndex].balance = parseFloat(
        accountsLive[keyIndex].balance
      ).toFixed(2);

      if (usersLive && usersLive[accountsLive[keyIndex].userId]) {
        accountsLive[keyIndex].fullName = `${
          usersLive[accountsLive[keyIndex].userId].name
        } ${usersLive[accountsLive[keyIndex].userId].surname}`;
        accountsLive[keyIndex].email =
          usersLive[accountsLive[keyIndex].userId].email;
      }

      dataLive.push(accountsLive[keyIndex]);
    });
  }

  let dataDemo = [];
  if (!!accountsDemo) {
    accountsDemo.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      if (Number.isInteger(accountsDemo[keyIndex].timeCreated)) {
        accountsDemo[keyIndex].timeCreated = moment(
          accountsDemo[keyIndex].timeCreated * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (Number.isInteger(accountsDemo[keyIndex].timeApproved)) {
        accountsDemo[keyIndex].timeApproved = moment(
          accountsDemo[keyIndex].timeApproved * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (usersDemo && usersDemo[accountsDemo[keyIndex].userId]) {
        accountsDemo[keyIndex].fullName = `${
          usersDemo[accountsDemo[keyIndex].userId].name
        } ${usersDemo[accountsDemo[keyIndex].userId].surname}`;
        accountsDemo[keyIndex].email =
          usersDemo[accountsDemo[keyIndex].userId].email;
      }

      dataDemo.push(accountsDemo[keyIndex]);
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="pt-3 px-3">
            Trading Accounts
            {state.loading ? (
              <Spinner animation="border" size="sm" className="float-right" />
            ) : null}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="live"
            transition={false}
            id="account-approved-tabs"
          >
            <Tab eventKey="live" title="LIVE" className="py-4">
              <BootstrapTable
                bootstrap4
                keyField="_id"
                data={dataLive}
                columns={columnsLive}
                defaultSorted={defaultSorted}
                hover={true}
                pagination={pagination}
                filter={filterFactory()}
                rowEvents={rowEvents}
                headerClasses="tableHeaderClass"
              />
            </Tab>
            <Tab eventKey="demo" title="DEMO" className="py-4">
              <BootstrapTable
                bootstrap4
                keyField="_id"
                data={dataDemo}
                columns={columnsDemo}
                defaultSorted={defaultSorted}
                hover={true}
                pagination={pagination}
                filter={filterFactory()}
                rowEvents={rowEvents}
                headerClasses="tableHeaderClass"
              />
            </Tab>
          </Tabs>

          <Modal
            show={state.showAccountModal}
            onHide={handleCloseModal}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update account</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={e => handleSubmit(e)}>
                <Form.Group>
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.fullName}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.email}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Acc. type</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.type}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Platform</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.platform}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Base currency</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.baseCurrency}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Acc. number</Form.Label>
                  <Form.Control
                    type="number"
                    name="number"
                    placeholder="Enter acc. number"
                    onChange={e => handleUpdateAccount(e)}
                    disabled
                    defaultValue={state.selectedAccount.number}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {state.editAccountErrors.number}
                  </Form.Text>
                </Form.Group>

                {/*<Form.Group>*/}
                {/*  <Form.Label>Acc. password</Form.Label>*/}
                {/*  <Form.Control*/}
                {/*    type="text"*/}
                {/*    name="password"*/}
                {/*    placeholder="Enter acc. password"*/}
                {/*    onChange={e => handleUpdateAccount(e)}*/}
                {/*    defaultValue={state.selectedAccount.password}*/}
                {/*  />*/}
                {/*  <Form.Text style={{ color: "red" }}>*/}
                {/*    {state.editAccountErrors.password}*/}
                {/*  </Form.Text>*/}
                {/*</Form.Group>*/}

                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    required
                    onChange={e => handleUpdateAccount(e)}
                    defaultValue={state.selectedAccount.status}
                  >
                    <option value="">Choose account status</option>
                    {getAccountStatusSelectListOptions()}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="comment"
                    onChange={e => handleUpdateAccount(e)}
                    defaultValue={state.selectedAccount.comment}
                  />
                </Form.Group>

                <Form.Text style={{ color: "red" }}>
                  {state.editAccountErrors.errors ||
                    state.editAccountErrors.server}
                  <br />
                  <br />
                </Form.Text>

                <Form.Row className="justify-content-between">
                  <Button
                    variant="secondary"
                    className="greyButton"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    className="darkBlueButton"
                    style={{ fontWeight: "normal" }}
                    disabled={state.disableEditAccountBtn}
                  >
                    {state.disableEditAccountBtn ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Update
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Form.Row>
              </Form>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default TradingAccounts;
