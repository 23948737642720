import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Card, Form, Button } from "react-bootstrap";
import {
  adminUpdateProfessionalClientStatus,
  adminGetProfessionalClientApplication,
} from "../../../../redux/actions/adminActions";
import classes from "./Profile.module.css";
import { NotificationManager } from "react-notifications";

const ProfessionalClientStatus = props => {
  const [profData, setProfData] = useState({});
  const [showViewApp, setShowViewApp] = useState(false);

  useEffect(() => {
    adminGetProfessionalClientApplication(props.user._id)
      .then(res => {
        setShowViewApp(res.data !== null);
      })
      .catch(err => console.log(err));
  }, []);

  const handleChange = e => {
    let target = e.target.name;
    let value = e.target.value;
    let checked = e.target.checked;

    if (target === "disabledProfessionalClientApplication") value = checked;

    let updateData = {
      userId: props.user._id,
      [target]: value,
    };
    if (target === "clientStatus" && value === "professional") {
      updateData.disabledProfessionalClientApplication = true;
      document.getElementById("choice03").checked = true;
    }

    adminUpdateProfessionalClientStatus(updateData)
      .then(res => {
        if (res.data.success) {
          NotificationManager.success("Update successful!", "", 5000);
        } else {
          NotificationManager.error(res.data.error, "", 5000);
        }
      })
      .catch(err => console.log(err));

    setProfData(prevState => {
      return { ...prevState, [target]: value };
    });
  };

  return (
    <Card className={classes.card}>
      <Card.Body className="p-0">
        <p
          className={[
            "p-3 m-0 border-bottom fontWeight500",
            classes.cardHeader,
          ].join(" ")}
        >
          Professional Client Status
        </p>

        <br />

        <Form.Row className="px-3">
          <Form.Group as={Col} style={{ maxWidth: "80px" }}>
            <label htmlFor="choice01" className={classes.complianceText}>
              Retail Client
            </label>
          </Form.Group>
          <Form.Group as={Col} style={{ maxWidth: "28px" }}>
            <Form.Check
              custom
              inline
              name="clientStatus"
              label=""
              type="checkbox"
              id="choice01"
              value="retail"
              onChange={e => handleChange(e, "retail")}
              checked={
                (!profData.clientStatus &&
                  props.user.clientStatus &&
                  props.user.clientStatus === "retail") ||
                (profData.clientStatus && profData.clientStatus === "retail")
              }
            />
          </Form.Group>
          <Form.Group as={Col} style={{ maxWidth: "120px" }}>
            <label htmlFor="choice02" className={classes.complianceText}>
              Professional Client
            </label>
          </Form.Group>
          <Form.Group as={Col} style={{ maxWidth: "28px" }}>
            <Form.Check
              custom
              inline
              name="clientStatus"
              label=""
              type="checkbox"
              id="choice02"
              value="professional"
              onChange={e => handleChange(e, "professional")}
              checked={
                (!profData.clientStatus &&
                  props.user.clientStatus &&
                  props.user.clientStatus === "professional") ||
                (profData.clientStatus &&
                  profData.clientStatus === "professional")
              }
            />
          </Form.Group>
        </Form.Row>

        <Form.Row className="px-3">
          <Form.Group as={Col} style={{ maxWidth: "230px" }}>
            <label htmlFor="choice03" className={classes.complianceText}>
              Remove Professional Client Application
            </label>
          </Form.Group>
          <Form.Group as={Col} style={{ maxWidth: "28px" }}>
            <Form.Check
              custom
              inline
              name="disabledProfessionalClientApplication"
              label=""
              type="checkbox"
              id="choice03"
              onChange={e => handleChange(e)}
              checked={profData.disabledProfessionalClientApplication}
            />
          </Form.Group>
        </Form.Row>

        {showViewApp ? (
          <div className="px-3">
            <Button
              type="button"
              variant="link"
              as={Link}
              to={`/user/professional/${props.user._id}`}
              className={[
                classes.complianceText,
                "d-block p-0 mb-3 text-left text-dark textDecorationUnderline",
              ].join(" ")}
            >
              View Professional Client Application
            </Button>
          </div>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default ProfessionalClientStatus;
