import React, { useState, useEffect } from "react";
import classes from "./Profile.module.css";
import { adminGetProfessionalClientApplication } from "../../../../redux/actions/adminActions";
import { Card } from "react-bootstrap";
import { countryList } from "../../../../config/constants";

const ProfessionalApplication = props => {
  const [data, setData] = useState(null);

  useEffect(() => {
    adminGetProfessionalClientApplication(props.userId)
      .then(res => {
        setData(res.data);
      })
      .catch(err => console.log(err));
  }, [props.userId]);

  return (
    <Card className={classes.card}>
      <Card.Body className="p-0">
        <p
          className={[
            "p-3 m-0 border-bottom fontWeight500",
            classes.cardHeader,
          ].join(" ")}
        >
          Client Application
        </p>

        {data ? (
          <>
            <p className={[classes.complianceText, "mt-3 mx-3"].join(" ")}>
              You have traded, in significant size, in the spread bet/forex/CFD
              markets or other leveraged products (e.g. indices, shares, spot
              FX, futures, options, other derivatives etc.) at an average
              frequency of 10 transactions per quarter over the previous four
              quarters. <strong>{data.question01}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 mx-3"].join(" ")}>
              Please provide the average number in the spread bet/Forex/CFDs
              markets or other leveraged products of significantly-sized trades
              per quarter over the last four quarters from all brokers/trading
              partners you trade with.{" "}
              <strong>{data.question02} trades average</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 mx-3"].join(" ")}>
              The size of your financial instrument portfolio, defined as
              including cash deposits and financial instruments, exceeds EUR
              500,000 (or equivalent in your local currency). Acceptable
              examples of savings and investments: Shares, Money market
              instruments, Units in collective investments e.g. unit trusts,
              UCITS, Cash, ISAs (cash and stocks and shares), CFD and spread
              betting accounts, Derivative contracts (futures, options, swaps
              and forwards) relating to securities, currencies, interest rates,
              financial indices or commodities, SIPPs. (Unacceptable examples of
              savings and investments: Managed pensions, Property, Non-liquid
              assets). <strong>{data.question03}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 mx-3"].join(" ")}>
              You work or have previously worked in the financial sector for at
              least one year in a professional position, which requires
              knowledge of the transactions or services envisaged.{" "}
              <strong>{data.question04}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 mx-3"].join(" ")}>
              What is the name of your most relevant employer? (We respect your
              privacy. Your employer will not be contacted).
              <br />
              <strong>{data.question05}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 mx-3"].join(" ")}>
              What role did you hold with this employer?{" "}
              <strong>{data.question06}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 mx-3"].join(" ")}>
              Is this employer that you have stated above regulated by a
              financial regulatory body? <strong>{data.question07}</strong>
            </p>
            <p className={[classes.complianceText, "mt-3 mx-3"].join(" ")}>
              How has this role provided you with experience in CFDs and/or
              Forex?
              <br />
              <strong>{data.question08}</strong>
            </p>
          </>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default ProfessionalApplication;
